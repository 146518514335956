<template>
  <van-tabbar
    v-model="tabbarIndex"
    @click="activeTabbar()"
    safe-area-inset-bottom
    :border="false"
    inactive-color="#626262"
    active-color="#06EB75"
    z-index="99"
    :fixed="true"
  >
    <van-tabbar-item to="/" name="home" class="home">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav1.active : icon_nav1.normal"
        size="30"
      />
      {{ $t("footer[0]") }}
    </van-tabbar-item>
    <van-tabbar-item name="hall" class="task" @click="clickHall">
      <img
              style="width: 26px; height: 26px"
              slot="icon"
              slot-scope="props"
              :src="props.active ? icon_nav4.active : icon_nav4.normal"
      />
      {{ $t("footer[1]") }}
    </van-tabbar-item>
    <van-tabbar-item to="/myTask" name="myTask" class="task">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav2.active : icon_nav2.normal"
      />
      {{ $t("footer[2]") }}
    </van-tabbar-item>
     <van-tabbar-item to="/user" name="user" class="user">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav5.active : icon_nav5.normal"
      />
      {{ $t("footer[3]") }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: [],
  data() {
    return {
      tabbarIndex: this.$route.matched[0].meta.active,
      icon_nav1: {
        normal: "./static/icon/index.png",
        active: "./static/icon/index_select.png",
      },
      icon_nav2: {
        normal: "./static/icon/task.png",
        active: "./static/icon/task_select.png",
      },
      icon_nav3: {
        normal: "./static/icon/miliao.png",
        active: "./static/icon/miliao_select.png",
      },
      icon_nav4: {
        normal: "./static/icon/vip.png",
        active: "./static/icon/vip_select.png",
      },
      icon_nav5: {
        normal: "./static/icon/user.png",
        active: "./static/icon/user_select.png",
      },
      icon_nav6: {
        normal: "./static/icon/profit.png",
        active: "./static/icon/profit_select.png",
      },
      icon_nav7: {
        normal: "./static/icon/activity.png",
        active: "./static/icon/activity_select.png",
      },
    };
  },
  computed: {},
  watch: {
    tabbarIndex(val) {
      console.log(this.tabbarIndex);
    },
  },
  created() {},
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    activeTabbar(index) {
      console.log(this.tabbarIndex);
    },
    clickHall(){
      let userinfo = this.UserInfo;
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.push("/");
          }else if (parseFloat(userinfo.bus_deposit)>0&&parseFloat(userinfo.bus_amount)>0){
            this.$router.push("/busauth");
          }else{
            this.$router.push("/hall");
          }
        }else{
          this.$router.push("/hall");
        }
      });
    }
  },
};
</script>
<style scoped>
  .van-tabbar {
    height: 62px;
    /* background: url('../../public/skin/home/bg_menu.png') no-repeat center 0; */
    background-size: contain;
    background-color: #191C23;
    /*border:2px solid #DCDCDC;*/
    /*box-shadow: 3px 3px 0px 0px #888;*/
    /*background: #0e1526;*/
    box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 0.31);
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
  }

  .van-tabbar-item__icon {
    font-size: 24px;
  }

  .van-tabbar-item__icon img {
    height: 24px;
  }

  .van-tabbar-item__icon .addTask {
    height: 72px;
    margin: -38px 0 -10px;
  }

  .van-tabbar-item--active {
    /*background: linear-gradient(to right, #2ddaff, #0049e7);*/
    -webkit-background-clip: text;
  }
  .van-tabbar-item--active >>> .van-tabbar-item__text {
    background: #0076FA;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .van-tabbar-item >>> .van-tabbar-item__text {
    font-size: 13px !important;
    font-family: Alibaba !important;
    margin-top: 2px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .van-tabbar >>> .van-tabbar-item {
    width: 20%;
  }
</style>