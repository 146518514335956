export default {
    mixRecharge: ["Veuillez renseigner votre adresse e-mail"],
    language: "Langue",
    common: ["un service en ligne", "annuler la suspension","confirmer","Annuler"],
    upload: ["Téléchargement...", "mauvais format", "téléchargé avec succès", "le téléchargement a échoué"],
    vanPull: ["plus de données", "pas de données"],
    login: {
        text: ["Langue","Crypto","Connexion"],
        label: ["se connecter...", "connectez-vous immédiatement"],
        placeholder: ["merci d'entrer votre email ou téléphone ", "s'il vous plait entrez votre mot de passe"],
        default: ["Pas de compte?", "S'inscrire maintenant","mot de passe oublié?","Mot de passe oublié"],
        codes:["Le compte n'existe pas","Le mot de passe est incorrect","Le compte est gelé","échouer"]
    },
    register: {
        text: [
            "Crypto",
            "Envoi du code de vérification...",
            "Cliquez pour obtenir",
            "enregistrement...",
            "S'inscrire maintenant",
        ],
        placeholder: [
            "merci d'entrer votre email",
            "veuillez entrer le code de vérification",
            "s'il vous plait entrez votre mot de passe",
            "Veuillez confirmer votre mot de passe",
            "Veuillez entrer le code d'invitation",
            "deux mots de passe différents",
            "veuillez entrer le code de vérification",
            "email invalide"
        ],
        label: ["Vous avez déjà un compte?{a} {line}", "retour connexion"],
        codes:["Le compte existe déjà","pas de code de vérification","code de vérification incorrect","les deux mots de passe ne correspondent pas","Le référent n'existe pas","échouer"]
    },
    resetpwd:["Mot de passe oublié","merci d'entrer votre email","veuillez entrer le code de vérification","Cliquez pour obtenir","veuillez entrer le mot de passe","réinitialiser le mot de passe"],
    footer: ["page de garde", "salle", "Ordre", "mien"],

    home: {
        label:["accueillir"],
        broadcast: "félicitations membre{member} devenir{grade}",
        menu: ["Journal des fonds", "Tutoriel pour débutant", "Invitation à s'inscrire", "Contactez le service à la clientèle"],
        noticeTitle: "Dernière annonce",
        msg: "mission non ouverte",
        video: "Pas de tutoriel vidéo",
    },
    hall:{
        default:[
            "parquet","solde","je veux acheter","je veux vendre","Confirmer la vente",
            "Liste de commandes","ordre de vente","vendre au marchand","Commande en cours","acheter","vendre"
        ],
        list:[
            'quantité','Prix ​​unitaire','Invendu','Vendre USDT','Veuillez saisir le montant USDT','obtenir le montant',"quota",'Acheter USDT','Montant dépensé'
        ],
        kbip:["révoquer","Montant excessif","doit être dans le montant"]
    },
    msglist:[
        "liste de messages"
    ],
    sell:{
        placeholder:["Veuillez entrer le prix de vente","Veuillez saisir la quantité vendue"],
        label:["solde(USDT)","Nom","compte bancaire","Prix ​​actuel(USDT)","vendre au meilleur prix","prix de vente","Vendre USDT Quantité","meilleur prix","tout","OK pour vendre"]
    },
    buy:{
        placeholder:["Veuillez entrer le prix d'achat","Veuillez entrer la quantité d'achat","montant minimal","quantité maximale"],
        label:["compte bancaire(USDT)","Nom","compte bancaire","Prix ​​actuel(USDT)","acheter au meilleur prix","prix d'achat","Acheter la quantité","meilleur prix","tout","Confirmer l'achat","quota"]
    },
    user: {
        default: [
            "centre personnel",
            "se connecter au compte",
            "Code d'invitation",
            "se déconnecter",
            "solde",
            "or",
            "porte monnaie",
        ],
        menu: [
            "mes atouts",
            "liste d'équipe",
            "liaison de compte",
            "détails du compte",
            "Présentation de la plate-forme",
            "se déconnecter",
            "portefeuille d'intérêts",
        ],
    },
    bindAccount: {
        default: ["lier le compte","nous faire parvenir"],
        fields: ["pays/région", "SWIFT", "SORT CODE","Nom de banque","IBAN","nom du compte",'Nom','nom de famille','adresse','code postal','Ville','province ou état',"téléphone portable"],
        placeholder: ["Veuillez entrer un pays","Veuillez entrer SWIFT","Veuillez saisir le CODE DE SORTIE","Veuillez entrer le nom de la banque","Veuillez saisir l'IBAN","Veuillez entrer le numéro de compte bancaire",
            "veuillez entrer le prénom","Veuillez entrer un nom de famille","veuillez entrer l'adresse","Veuillez entrer un code postal","Veuillez entrer une ville","Veuillez saisir une province ou un état","Veuillez entrer le numéro de téléphone"],
    },
    wallet: {
        default: [
            "mes atouts",
            "recharger",
            "se désister",
            "Recharger l'enregistrement",
            "Registre des retraits",
            "Montant rechargé",
            "Montant vendu",
            "Gagné",
            "solde du compte",
            "solde",
            "Montant de la recharge(USDT)",
            "Montant de la vente",
            "Revenu total",
            "entreprise de mise à niveau",
            "Marchand de mise à niveau, veuillez contacter le service client",
            "Confirmer les affaires réglées",
            "Authentification du marchand",
            "Vous devez payer","bon de paiement","Dépôt marchand","Authentifiez-vous maintenant","Nouvelles","Vous devez payer","Que ce soit pour recharger si le solde est insuffisant"
        ],
        label: [
            "Méthode de retrait",
            "Montant de retrait",
            "Mot de passe du fonds",
            "nous faire parvenir",
            "Numéro de téléphone",
            "Courrier",
            "IFSC",
            "Bien sûr",
        ],
        placeholder: [
            "Choisissez une méthode de retrait",
            "Veuillez entrer le montant du retrait",
            "Veuillez entrer le mot de passe du fonds",
            "Veuillez choisir une méthode de retrait",
            "Veuillez entrer le numéro de téléphone portable du bénéficiaire",
            "Veuillez saisir l'adresse e-mail du destinataire",
            "Veuillez saisir le bénéficiaire IFSC",
        ],
        msg: [
            "Vous n'avez pas défini le mot de passe du fonds, veuillez d'abord le définir",
            "Vous n'avez pas encore lié de compte PIX, veuillez d'abord le lier",
            "Êtes-vous sûr de vouloir retirer de l'argent ?",
        ],
    },
    recharge: {
        default: [
            "recharge de portefeuille",
            "Précharge du portefeuille",
            "Méthode de recharge",
            "recharger",
            "confirmer",
        ],
        label: [
            "Catégorie",
            "adresse",
            "Montant de la recharge",
            "Entrez le montant",
            "télécharger le certificat",
            "copier avec succès",
            "Échange pré-dépôt"
        ],
        placeholder: [
            "taper",
            "adresse",
        ],
        info: [
            "Montant de la recharge",
            "numéro de commande",
            "Banque bénéficiaire",
            "Compte de recouvrement",
            "bénéficiaire",
            "copie",
        ]
    },
    task: {
        tabs: ["tout","En traitement", "expiré", "complété","Attendre que le marchand paye","En attente de confirmation"],
        default: ["Liste de commandes",],
        msg: ["Poster le commentaire", "Envoyé, veuillez attendre l'examen", "Échec de la soumission, veuillez soumettre à nouveau"],
    },
    userInfo: {
        default: [
            "renseignements personnels",
            "avatar",
            "numéro de compte",
            "Courrier",
            "Alipay",
            "des informations détaillées",
            "changer le mot de passe",
            "Mot de passe du fonds",
            "cliquez sur paramètres",
            "Modifier l'avatar",
            "Modifier le mot de passe de connexion",
            "Modifier le mot de passe du fonds",
            "nous faire parvenir",
            "Vider le cache",
            "afficher les informations",
            "Surnom",
            "Code de Parrainage"
        ],
        label: [
            "mot de passe de connexion d'origine",
            "Nouveau mot de passe de connexion",
            "Confirmez le mot de passe",
            "Mot de passe du fonds d'origine",
            "nouveau mot de passe de fonds",
            "Confirmez le mot de passe",
            "enregistrer"
        ],
        placeholder: [
            "Veuillez entrer le mot de passe de connexion d'origine",
            "Veuillez entrer un nouveau mot de passe de connexion",
            "Veuillez confirmer le mot de passe de connexion",
            "Veuillez saisir le mot de passe d'origine du fonds",
            "Veuillez entrer le nouveau mot de passe du fonds",
            "Veuillez confirmer le mot de passe du fonds",
        ],
    },
    fundRecord: {
        default: ["registre des dépenses", "Recharger l'enregistrement", "journal des actifs", "Charge", "recevoir", "bifurquer"],
        tabs: ["le revenu", "dépense", "recharger"],
    },
    dialog: [
        "indice",
        "Bien sûr",
        "soumet...",
        "copier avec succès",
        "La version basse du système IOS ne prend pas en charge",
        "enregistrement...",
        "Chargement en cours...",
    ],
    serviceCenter: [
        "Service Clients",
        "Salut, je suis le service client exclusif ~",
        "Heureux de vous servir",
        "en libre service",
        "service client en ligne",
        "Recharger le service client",
        "Service Clients",
    ],
    userTaskRecord: [
        "Ma commande",
        "ordre de vente",
        "ordre d'achat",
        "statut actuel",
        "obtenir des commissions",
        "complété",
    ],
    withdrawlist: [
        "Registre des retraits",
    ],
    teamReport: {
        default: [
            "liste d'équipe",
        ],
    },
    common2:['Tutoriel pour débutant',"Contactez le service à la clientèle","Conditions d'utilisation, voir les détails","service client 1","service client 2"],
    common3:['Succès',"Présentation de la plate-forme"],
    invite:['partager la promotion','Mon code d\'invitation',"Copiez le code d'invitation pour inviter plus d'amis à rejoindre","Copier"],
    common4:["Authentification par nom réel", "Soumission réussie", "Précautions", "Contenu des questions", "Montant du paiement", "Carte d'identité positive",
        "Preuve financière", "Veuillez d'abord compléter la liaison du compte", "Veuillez d'abord compléter l'authentification du nom réel"],
    common5:["vendre","minute","vers l'avant","Enregistrez le code QR","Ouvert","Le compte est gelé et ne peut pas être échangé temporairement","Téléchargement de l'application"],
    common6:["Commande de recharge","numéro de commande","le montant","temps","Etat"],
    hall2:["Le montant minimum de vente est","","Le nombre de ventes dépasse la limite quotidienne"],
    register2:["Inscription par e-mail", "Enregistrement du téléphone portable", "Veuillez entrer votre numéro de téléphone portable"],
    withdraw2:["Retrait", "Compte vendeur", "Solde du compte", "Montant à payer", "Payer maintenant", "Il reste des tâches inachevées et le retrait est temporairement désactivé", "Le nombre de retraits par jour dépasse", "Remarques"],
    mytask2:["Compte marchand", "Prix", "Montant","solde du compte","Montant à payer","temps de match","détails de la commande","La quantité ne peut pas être 0","Solde insuffisant","Le prix ne peut pas être 0","erreur de plage"],
    taskOrder2:["Vous avez encore des commandes en attente, qui ne peuvent pas être annulées","Solde insuffisant","confirmer","échouer","Vous devez encore compléter la commande pour retirer de l'argent:"],
    busAuth2:['Surclassement marchand','Je suis d\'accord',"Confirmer la mise à niveau","entreprise de mise à niveau","Si vous avez besoin de modifier les informations de votre carte bancaire, veuillez contacter le service client","Le compte actuel doit être mis à niveau vers un compte marchand"],
    recharge2:['Le montant ne peut pas être 0','Les captures d\'écran doivent être téléchargées'],
    buy3:["compte à rebours","Heure","taper",'Commande de prestations','Ordre de syndication','Limiter le nombre de personnes','N\'entrez pas un nombre illimité de personnes','Spécifiez l\'ID utilisateur','Veuillez entrer l\'identifiant spécifié'],
    hall3:["Jours", "Heures", "Minutes", "Secondes", "Ordre combiné", "Compte", "Expiré"],
    sell3:['Le prix de vente ne peut être supérieur au meilleur prix'],
    busAuth3:["Membre", "Marchand", "Marchand de la Couronne"],
    recharge3:["calculer"],
    withdraw3:{
        fields:["Nom d'ouverture de compte", "numéro de téléphone portable", "IBAN", "nom de la banque", "code banque"],
        placeholder:["Veuillez saisir le nom du compte", "Veuillez saisir le numéro de téléphone portable", "Veuillez saisir l'IBAN", "Veuillez saisir le nom de la banque", "Veuillez sélectionner le code bancaire"],
    },
    my2:["jour", "taux d'intérêt", "portefeuille d'intérêts", "détails"],
    fin:["Montant", "Durée", "Taux d'intérêt", "Heure d'expiration"]
};
