export default {
  mixRecharge: ["Por favor, introduzca su dirección de correo electrónico"],
  language: "Idioma",
  common: ["Servicio de atención al cliente en línea", "Anular", "Confirmar", "Cancelar"],
  upload: ["Carga en curso..." , "Formato de error", "Carga exitosa", "Carga fallida"],
  vanPull: ["No hay más datos", "No hay datos"],
  login: {
    text: ["Idioma", "NYBOC", "Iniciar sesión"],
    label: ["Iniciando sesión..." , "Inicie sesión ahora"],
    placeholder: ["Por favor, introduzca su dirección de correo electrónico", "Por favor, introduzca su contraseña de acceso"],
    default: ["¿No tiene un número de cuenta?" , "Regístrese ahora", "¿Olvidó su contraseña?" , "Olvidó su contraseña"],
    codes:["La cuenta no existe", "La contraseña es incorrecta", "La cuenta está congelada", "Fallo"]
  },
  register: {
    text: [
      "NYBOC",
      "Se está enviando el código de autenticación..." ,
      "Haz clic para obtener",
      "Registrando..." ,
      "Regístrese ahora",
    ],
    placeholder: [
      "Por favor, introduzca su buzón",
      "Por favor, introduzca el código de verificación",
      "Por favor, introduzca su contraseña de acceso",
      "Por favor, confirme su contraseña",
      "Por favor, introduzca el código de invitación",
      "La contraseña es diferente dos veces",
      "Por favor, introduzca el código de verificación",
      "Buzón inválido"
    ],
    label: ["¿Ya tiene una cuenta? {a} {línea}", "Volver al inicio de sesión"],
    codes:["La cuenta ya existe", "No hay código de validación", "Código de validación incorrecto", "Dos contraseñas no coinciden", "La referencia no existe", "Fallo"]
  },
  resetpwd: ["Ha olvidado su contraseña", "Por favor, introduzca su correo electrónico", "Por favor, introduzca el código de verificación", "Haga clic para obtener", "Por favor, introduzca su contraseña", "Restablezca su contraseña"],
  footer: ["Inicio", "Vestíbulo", "Pedidos", "Mi"],

  home: {
    label:["Bienvenido"],
    broadcast: "Enhorabuena al socio {member} por convertirse en {grade}",
    menu: ["Registro de dinero", "Tutoriales para novatos", "Invitación a registrarse", "Servicio al cliente"],
    noticeTitle: "Últimos anuncios",
    msg: "Misión no abierta",
    video: "No hay video tutorial por ahora",
  },
  hall:{
    default:[
      "Sala de operaciones", "Saldo", "Quiero comprar", "Quiero vender", "Confirmar venta",
      "Lista de pedidos", "Órdenes de venta", "Vender a comerciante", "Órdenes pendientes", "Comprar", "Vender"
    ],
    list:[
      'Cantidad','Precio unitario','Pendiente','USDT vendidas','Por favor, introduzca la cantidad de USDT','Obtener cantidad','Cantidad','Comprar USDT','Cantidad de coste'
    ],
    kbip:["revocar", "importe excedido", "debe estar dentro del importe"]
  },
  msglist:[
    "lista de mensajes"
  ],
  sell:{
    placeholder:["Por favor, introduzca el precio de venta", "Por favor, introduzca la cantidad de venta"],
    label:["Saldo(USDT)", "Nombre", "Cuenta bancaria", "Precio actual(USDT)", "Mejor precio de venta", "Precio de venta", "Cantidad de venta", "Mejor precio", "Todos", "Venta confirmada"]
  },
  buy:{
    placeholder:["Por favor, introduzca un precio de compra", "Por favor, introduzca una cantidad de compra", "importe mínimo", "importe máximo"],
    label:["Saldo(USDT)", "Nombre", "Cuenta bancaria", "Precio actual(USDT)", "Precio de compra", "Cantidad de compra", "Mejor precio", "Todo", "Confirmar compra", "Importe"]
  },
  user: {
    default: [
      "Centro personal",
      "Cuenta de acceso",
      "Código de invitación",
      "Cierre de sesión",
      "Balance",
      "Monedas",
      "Cartera",
    ],
    menu: [
      "Mis activos",
      "Lista de equipos",
      "Vinculación de la cuenta",
      "Datos de la cuenta",
      "Introducción a la plataforma",
      "Cierre de sesión",
    ],
  },
  bindAccount: {
    default: ["BindAccount", "Submit"],
    fields: ["PAÍS", "SWIFT", "CÓDIGO DE HIPOTECA", "NOMBRE DEL BANCO", "IBAN", "NOMBRE DE LA CUENTA", 'nombre', 'apellido', 'dirección', 'código postal', 'ciudad', 'provincia o estado',"Teléfono"],
    placeholder: ["Por favor, introduzca el país/región", "Por favor, introduzca el SWIFT", "Por favor, introduzca el código SORT", "Por favor, introduzca el nombre del banco", "Por favor, introduzca el IBAN", "Por favor, introduzca el número de cuenta bancaria",
      "Por favor, introduzca el nombre", "Por favor, introduzca los apellidos", "Por favor, introduzca la dirección", "Por favor, introduzca el código postal", "Por favor, introduzca la ciudad",
      "Por favor, introduzca la provincia o el estado","Por favor, introduzca teléfono"],
  },
  wallet: {
    default: [
      "Mis activos",
      "Recarga",
      "Retiradas",
      "Historia de la recarga",
      "Historia de la retirada",
      "Importe de la recarga",
      "Cantidad vendida",
      "Ganancias",
      "Saldo de la cuenta",
      "Balance",
      "Importe de la recarga (USDT)",
      "Importe de la venta",
      "Ganancias totales",
      "Comerciantes",
      "Actualizar comerciante, por favor, póngase en contacto con el servicio de atención al cliente",
      "Confirmar comerciante",
      "verificación comercial",
      "Debe pagar", "Certificado de pago", "Garantía del comerciante", "Verificar ahora", "Información", "Debe pagar", "Saldo insuficiente para recargar"
    ],
    label: [
      "Método de retirada",
      "Importe de la retirada",
      "Contraseña de fondos",
      "Enviar",
      "Número de móvil",
      "Buzón",
      "IFSC",
      "OK",
    ],
    placeholder: [
      "Seleccione el método de retirada",
      "Por favor, introduzca el importe de la retirada",
      "Por favor, introduzca la contraseña de los fondos",
      "Por favor, seleccione el método de retirada",
      "Por favor, introduzca el número de teléfono móvil del destinatario",
      "Por favor, introduzca la dirección de correo electrónico del destinatario",
      "Por favor, introduzca el IFSC del beneficiario",
    ],
    msg: [
      "Todavía no ha establecido la contraseña para sus fondos, por favor, hágalo primero",
      "Todavía no ha vinculado su cuenta PIX, por favor, hágalo primero",
      "¿Seguro que quieres retirar fondos?" ,
    ],
  },
  recharge: {
    default: [
      "Recarga de cartera",
      "Precarga de la cartera",
      "Método de recarga",
      "Recarga",
      "Confirmar",
    ],
    label: [
      "Categoría",
      "Dirección",
      "Importe de la recarga",
      "Importe de entrada",
      "Cargar certificado",
      "Copia exitosa",
      "Intercambio de precarga"
    ],
    placeholder: [
      "tipo",
      "Dirección",
    ],
    info: [
      "Importe de la recarga",
      "Número de pedido",
      "Banco receptor",
      "Cuenta de cobro",
      "Beneficiario",
      "Copia",
    ]
  },
  task: {
    tabs: ["todos", "en curso", "caducado", "completado", "a la espera de que el comerciante pague", "a la espera de confirmación"],
    default: ["Lista de pedidos",],
    msg: ["Enviado para su revisión", "Enviado, por favor espere a la revisión", "Envío fallido, por favor vuelva a enviar"],
  },
  userInfo: {
    default: [
      "Información personal",
      "Avatar",
      "Cuenta",
      "Buzón",
      "Paypal",
      "Detalles",
      "Cambiar contraseña",
      "Contraseña de financiación",
      "Haga clic para fijar",
      "Modificar el avatar",
      "Cambiar la contraseña de inicio de sesión",
      "Cambiar la contraseña de financiación",
      "Enviar",
      "Caché vacía",
      "Ver información",
      "Apodo",
      "Código de referencia"
    ],
    label: [
      "Contraseña original",
      "Nueva contraseña",
      "Confirmar contraseña",
      "Contraseña original",
      "Nueva contraseña",
      "Confirmar contraseña",
      "Guardar"
    ],
    placeholder: [
      "Por favor, introduzca la contraseña original",
      "Por favor, introduzca una nueva contraseña",
      "Por favor, confirme su contraseña de acceso",
      "Por favor, introduzca su contraseña original",
      "Por favor, introduzca la nueva contraseña del fondo",
      "Por favor, confirme la contraseña de financiación",
    ],
  },
  fundRecord: {
    default: ["ExpenseRecord", "TopUpRecord", "AssetLog", "Charges", "Receipts", "Payments"],
    tabs: ["Ingresos", "Gastos", "Recarga"],
  },
  dialog: [
    "Consejo",
    "OK",
    "Sometiendo..." ,
    "Copiado con éxito",
    "Versión de IOS baja no soportada",
    "Registrando..." ,
    "Carga de datos..." ,
  ],
  serviceCenter: [
    "Centro de Atención al Cliente",
    "Hola, soy el servicio de atención al cliente dedicado~",
    "Feliz de ayudarte",
    "Autoservicio",
    "Servicio de atención al cliente en línea",
    "Servicio de atención al cliente de recarga",
    "Línea de atención al cliente",
  ],
  userTaskRecord: [
    "Mis órdenes",
    "Órdenes de venta",
    "Órdenes de compra",
    "Estado actual",
    "Comisión recibida",
    "Completado",
  ],
  withdrawlist: [
    "Historia de la retirada",
  ],
  teamReport: {
    default: [
      "TeamList",
    ],
  },
  common2:['Tutorial para novatos', "Servicio al cliente", "Condiciones del servicio, ver detalles", "Servicio de atención al cliente 1", "Servicio de atención al cliente 2"],
  common3:['Éxito', "Introducción a la plataforma"],
  invite:['Compartir promoción','Mi código de invitación', "Copiar código de invitación, invitar a más amigos a unirse", "Copiar"],
  common4:['Verificación del nombre real', "Enviado con éxito", "Notas", "Detalles", "Importe pagado", "Anverso del DNI",
    "Prueba financiera", "Por favor, vaya a completar primero la vinculación de la cuenta", "Por favor, complete primero la autenticación del nombre real"],
  common5:["Vender", "Minutos", "Pre", "Guardar código QR", "Abrir", "La cuenta está congelada, no puede operar temporalmente", "Descarga de la aplicación"],
  common6:["Orden de recarga", "Número de orden", "Importe", "Tiempo", "Estado"],
  hall2:["El importe mínimo de venta es","", "Vendido más veces que el límite diario"],
  register2:["Registro del buzón", "Registro del teléfono móvil", "Introduzca su número de teléfono móvil"],
  retirar2:["Retirar dinero en efectivo", "Cuenta del vendedor", "Saldo de la cuenta", "Cantidad a pagar", "Pagar ahora", "Todavía hay una tarea que completar, no puede retirar dinero en efectivo en este momento", "Se ha superado el número de retiros por día", "Observación"],
  mytask2:["Cuenta del comerciante", "Precio", "Importe", "Saldo de la cuenta", "Importe a pagar", "Tiempo de coincidencia", "Detalles del pedido", "La cantidad no puede ser 0", "Saldo insuficiente", "El precio no puede ser 0", "Error de rango"],
  taskOrder2:["Todavía tiene pedidos pendientes y no puede cancelarlos", "Saldo insuficiente",
    "Confirmed","Failed","You still need to complete the order to withdraw:"],
  busAuth2:['Actualización de comerciante', 'Acepto', "Confirmar la actualización", "Actualizar el comerciante", "Si necesita modificar la información de la tarjeta bancaria, comuníquese con el servicio al cliente", "La cuenta actual debe actualizarse a un comerciante cuenta"],
  recharge2:['El importe no puede ser 0','Debe subir una captura de pantalla'],
  buy3:["cuenta atrás", "hora", "tipo",'orden de beneficio','orden combinada','limitar el número de personas','no introducir un número ilimitado de personas','especificar id de usuario','por favor introduzca id especificado'],
  hall3:['días','horas','minutos','segundos','pedidos combinados','cuenta','expirado'],
  sell3:['El precio de venta no puede ser mayor que el mejor precio'],
  busAuth3:["Miembro", "Comerciante", "Comerciante de la Corona"],
  recharge3:["calcular"],
  withdraw3:{
    fields:["Nombre de la cuenta abierta", "Número de móvil", "IBAN", "Nombre del banco", "Código bancario"],
    placeholder:["Por favor, introduzca su nombre de cuenta", "Por favor, introduzca su número de móvil", "Por favor, introduzca el IBAN", "Por favor, introduzca el nombre de su banco", "Por favor, seleccione su código bancario"],
  },
  my2:["Días","Tasa de interés","InterésCartera"]
};