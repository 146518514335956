export default {
  mixRecharge: ["Inserisci il tuo indirizzo email"],
  language: "lingua",
  common: ["Servizio Online", "annullare la sospensione","Confermare","Annulla"],
  upload: ["caricamento...", "formato errato", "caricato con successo", "caricamento fallito"],
  vanPull: ["niente più dati", "nessun dato"],
  login: {
    text: ["lingua","Crypto","Login"],
    label: ["entrando...", "accedi subito"],
    placeholder: ["Inserisci la tua email o il tuo numero di cellulare", "per favore inserisci LA TUA password"],
    default: ["Nessun conto?", "Iscriviti ora","dimenticare la parola d'ordine?","Hai dimenticato la password"],
    codes:["l'account non esiste","password errata","L'account è bloccato","fallire"]
  },
  register: {
    text: [
      "Crypto",
      "Invio del codice di verifica...",
      "Clicca per ottenere",
      "registrazione...",
      "Iscriviti ora",
    ],
    placeholder: [
      "per favore inserisci la tua email",
      "si prega di inserire il codice di verifica",
      "per favore inserisci LA TUA password",
      "Si prega di confermare la password",
      "Inserisci il codice di invito",
      "due password diverse",
      "si prega di inserire il codice di verifica",
      "e-mail non valido"
    ],
    label: ["Hai già un account?{a} {line}", "indietro login"],
    codes:["L'account esiste già","nessun codice di verifica","Codice di verifica errato","Le due password non corrispondono","Il referente non esiste","fallire"]
  },
  resetpwd:["dimenticare la parola d'ordine","per favore inserisci la tua email","si prega di inserire il codice di verifica","Clicca per ottenere","Per favore, inserisci la password","Resetta la password"],
  footer: ["prima pagina", "sala", "Ordine", "mio"],

  home: {
    label:["Benvenuto"],
    broadcast: "congratulazioni membro{member} diventare{grade}",
    menu: ["Registro fondi", "Tutorial per principianti", "Invito alla registrazione", "Contattare il Servizio Clienti"],
    noticeTitle: "Ultimo annuncio",
    msg: "missione non aperta",
    video: "Nessun tutorial video",
  },
  hall:{
    default:[
      "piano di negoziazione","bilancia","voglio comprare","voglio vendere","Conferma vendita",
      "Lista degli ordini","ordine di vendita","vendere al commerciante","Ordine in attesa","acquistare","vendere"
    ],
    list:[
      'quantità','prezzo unitario','Invenduto','Vendi USDT',"Inserisci la quantità di USDT", "Ottieni l’importo","quota",'Compra USDT','Importo speso'
    ],
    kbip:["revocare","Importo in eccesso","deve rientrare nell'importo"]
  },
  msglist:[
    "elenco messaggi"
  ],
  sell:{
    placeholder:["Inserisci il prezzo di vendita","Inserisci la quantità di vendita"],
    label:["bilancia(USDT)","Nome","conto bancario","Prezzo attuale(USDT)","vendere il miglior prezzo","prezzo di vendita","Vendi quantità USDT","miglior prezzo","Tutto","OK per vendere"]
  },
  buy:{
    placeholder:["Inserisci il prezzo di acquisto","Si prega di inserire la quantità di acquisto","quantità minima","importo massimo"],
    label:["bilancia(USDT)","Nome","conto bancario","Prezzo attuale(USDT)","acquista il miglior prezzo","prezzo d'acquisto","Acquista quantità","miglior prezzo","Tutto","Conferma acquisto","quota"]
  },
  user: {
    default: [
      "centro personale",
      "accedi all'account",
      "Codice invito",
      "disconnessione",
      "bilancia",
      "oro",
      "portafoglio",
    ],
    menu: [
      "i miei beni",
      "elenco squadre",
      "vincolante dell'account",
      "Dettagli account",
      "Introduzione alla piattaforma",
      "disconnessione",
      "portafoglio interessi",
    ],
  },
  bindAccount: {
    default: ["vincolare l'account","invia"],
    fields: ["paese/regione", "SWIFT", "SORT CODE","nome della banca","IBAN","nome utente",'nome','cognome','indirizzo','codice postale','Città','provincia o stato',"cellulare"],
    placeholder: ["Inserisci un paese","Inserisci SWIFT","Si prega di inserire il SORT CODE","Inserisci il nome della banca","Inserisci l'IBAN","Si prega di inserire il numero di conto bancario",
      "si prega di inserire il nome","Inserisci un cognome","si prega di inserire l'indirizzo","Inserisci un codice postale","Inserisci una città","Inserisci una provincia o uno stato","Inserisci il numero di telefono"],
  },
  wallet: {
    default: [
      "i miei beni",
      "ricaricare",
      "ritirare",
      "Ricarica record",
      "Registro dei prelievi",
      "Importo ricaricato",
      "Importo venduto",
      "Guadagnato",
      "saldo del conto",
      "bilancia",
      "Importo di ricarica(USDT)",
      "Importo di vendita",
      "Entrate totali",
      "aggiornare gli affari",
      "Aggiorna commerciante, contatta il servizio clienti",
      "Conferma affari risolti",
      "Autenticazione commerciante",
      "devi pagare","buono di pagamento","Deposito mercantile","Autenticati ora","Informazione","devi pagare","Se ricaricare se il saldo è insufficiente"
    ],
    label: [
      "Metodo di prelievo",
      "Importo del prelievo",
      "Parola d'ordine del fondo",
      "invia",
      "Numero di telefono",
      "Posta",
      "IFSC",
      "Sicuro",
    ],
    placeholder: [
      "Scegli un metodo di prelievo",
      "Inserisci l'importo del prelievo",
      "Inserisci la password del fondo",
      "Si prega di scegliere un metodo di prelievo",
      "Inserisci il numero di cellulare del beneficiario",
      "Inserisci l'indirizzo email del destinatario",
      "Inserisci il beneficiario IFSC",
    ],
    msg: [
      "Non hai impostato la password del fondo, impostala prima",
      "Non hai ancora associato l'account PIX, per favore collega prima",
      "conferma di recesso?",
    ],
  },
  recharge: {
    default: [
      "ricarica del portafoglio",
      "Precarica portafoglio",
      "Metodo di ricarica",
      "ricaricare",
      "Confermare",
    ],
    label: [
      "categoria",
      "indirizzo",
      "Importo di ricarica",
      "Inserisci l'importo",
      "caricare il certificato",
      "copia con successo",
      "Scambio pre-deposito"
    ],
    placeholder: [
      "tipo",
      "indirizzo",
    ],
    info: [
      "Importo di ricarica",
      "numero d'ordine",
      "Banca di destinazione",
      "Conto di raccolta",
      "beneficiario",
      "copia",
    ]
  },
  task: {
    tabs: ["Tutto","in corso", "scaduto", "completato","Aspetto che il commerciante paghi","In attesa di conferma"],
    default: ["Lista degli ordini",],
    msg: ["Invia recensione", "Inviato, attendere la revisione", "Invio non riuscito, inviare di nuovo"],
  },
  userInfo: {
    default: [
      "informazione personale",
      "avatar",
      "numero di conto",
      "Posta",
      "Alipay",
      "informazioni dettagliate",
      "cambiare la password",
      "Parola d'ordine del fondo",
      "fare clic su impostazioni",
      "Modifica avatar",
      "Modifica password di accesso",
      "Cambia la password del fondo",
      "invia",
      "Svuotare la cache",
      "visualizzare le informazioni",
      "Soprannome",
      "codice di riferimento"
    ],
    label: [
      "password di accesso originale",
      "Nuova password di accesso",
      "Conferma password",
      "Password del fondo originale",
      "nuova password del fondo",
      "Conferma password",
      "salva"
    ],
    placeholder: [
      "Inserisci la password di accesso originale",
      "Inserisci una nuova password di accesso",
      "Si prega di confermare la password di accesso",
      "Inserisci la password del fondo originale",
      "Inserisci la nuova password del fondo",
      "Si prega di confermare la password del fondo",
    ],
  },
  fundRecord: {
    default: ["registro spese", "Ricarica record", "registro patrimoniale", "Carica", "ricevere", "ramo"],
    tabs: ["reddito", "spesa", "ricaricare"],
  },
  dialog: [
    "suggerimento",
    "Sicuro",
    "sta sottomettendo...",
    "copia con successo",
    "La versione bassa del sistema IOS non supporta",
    "registrazione...",
    "caricamento...",
  ],
  serviceCenter: [
    "Assistenza clienti",
    "Ciao, sono l'esclusivo servizio clienti~",
    "Felice di servirti",
    "self service",
    "servizio clienti in linea",
    "Ricarica il servizio clienti",
    "Line assistenza clienti",
  ],
  userTaskRecord: [
    "Il mio ordine",
    "ordine di vendita",
    "ordine di acquisto",
    "stato attuale",
    "ottenere commissioni",
    "completato",
  ],
  withdrawlist: [
    "Registro dei prelievi",
  ],
  teamReport: {
    default: [
      "elenco squadre",
    ],
  },
  common2:['Tutorial per principianti',"Contattare il Servizio Clienti","Termini di servizio, visualizza i dettagli","servizio clienti 1","servizio clienti 2"],
  common3:['successo',"Introduzione alla piattaforma"],
  invite:['promozione azionaria','il mio codice di invito',"Copia il codice di invito per invitare altri amici a unirsi","copia"],
  common4:['Verificato',"Inviato con successo","Precauzioni","Contenuto dell'articolo","importo del pagamento","Carta d'identità anteriore",
    "certificato finanziario","Completa prima l'associazione dell'account","Completa prima l'autenticazione con il nome reale"],
  common5:["vendere","minuto","inoltrare","Salva il codice QR","Aprire","L'account è bloccato e non può essere scambiato temporaneamente","Scarica l'APP"],
  common6:["Ordine di ricarica","numero d'ordine","l'importo","tempo","stato"],
  hall2:["L'importo minimo di vendita è","","Il numero di vendite supera il limite giornaliero"],
  register2:["registrazione email","Registra il tuo telefono","Inserisci il numero di telefono"],
  withdraw2:["ritirare","conto venditore","saldo del conto","Somma da pagare","paga subito","Ci sono ancora compiti incompiuti, temporaneamente impossibilitati a prelevare contanti","Il numero di prelievi al giorno supera","manuale di istruzioni"],
  mytask2:["conto commerciante","prezzo","l'importo","saldo del conto","Somma da pagare","tempo di partita","dettagli dell'ordine","La quantità non può essere 0","Equilibrio insufficiente","Il prezzo non può essere 0","errore di intervallo"],
  taskOrder2:["Hai ancora ordini in sospeso, che non possono essere annullati","Equilibrio insufficiente","Confermare","fallire","Devi ancora completare l'ordine per prelevare contanti:"],
  busAuth2:['Aggiornamento del commerciante',"Sono d'accordo'","Conferma l'aggiornamento","aggiornare gli affari","Se hai bisogno di modificare i dati della carta di credito, contatta il servizio clienti","L'account corrente deve essere aggiornato a un account commerciante"],
  recharge2:["L'importo non può essere 0",'Gli screenshot devono essere caricati'],
  buy3:["conto alla rovescia","Ora","tipo",'Ordine di beneficio','Ordine sindacale','Limita il numero di persone','Non inserire un numero illimitato di persone',"Specifica l'ID utente","Inserisci l'ID specificato"],
  hall3:["cielo","Ora","minuto","Secondo","Ordine sindacale","numero di conto",'essere scaduto'],
  sell3:['Il prezzo di vendita non può essere superiore al miglior prezzo'],
  busAuth3:["membro","mercante","Mercante di corone"],
  recharge3:["calcolare"],
  withdraw3:{
    fields:["nome utente", "numero di telefono", "IBAN","nome della banca","Codice bancario"],
    placeholder:["Inserisci il nome dell'account","Inserisci il numero di telefono","Inserisci l'IBAN","Inserisci il nome della banca","Si prega di selezionare il codice della banca"],
  },
  my2:["cielo","tasso d'interesse","portafoglio interessi","dettagli"],
  fin:["l'importo","il termine","tasso d'interesse","Data di scadenza"]
};
