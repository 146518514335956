export default {
  mixRecharge: ["Vul uw e-mailadres in"],
  language: "Taal",
  common: ["Online klantenservice", "Annuleer de opschorting","bevestigen","Annuleren"],
  upload: ["uploaden...", "Slecht formaat", "succesvol geupload", "upload mislukt"],
  vanPull: ["Geen gegevens meer", "geen gegevens"],
  login: {
    text: ["Taal","Crypto","Log in"],
    label: ["Inloggen...", "log direct in"],
    placeholder: ["Voer uw e-mailadres of mobiele telefoonnummer in", "Voer uw wachtwoord in"],
    default: ["Geen account?", "Meld u nu aan","vergeet het wachtwoord?","Je wachtwoord vergeten"],
    codes:["Account bestaat niet","Incorrect wachtwoord","Account is bevroren","mislukking"]
  },
  register: {
    text: [
      "Crypto",
      "Er wordt een verificatiecode verzonden...",
      "Klik om te krijgen",
      "Registreren...",
      "Meld u nu aan",
    ],
    placeholder: [
      "Voer uw e-mailadres in",
      "voer de verificatiecode in",
      "Voer uw wachtwoord in",
      "bevestig alstublieft uw wachtwoord",
      "Voer de uitnodigingscode in",
      "De twee wachtwoorden zijn verschillend",
      "voer de verificatiecode in",
      "Ongeldig e-mail"
    ],
    label: ["Heeft u al een account?{a} {line}", "Terug naar inloggen"],
    codes:["Account bestaat al","Geen verificatiecode","Onjuiste verificatiecode","Twee wachtwoorden zijn inconsistent","De aanbeveler bestaat niet","mislukking"]
  },
  resetpwd:["vergeet het wachtwoord","Voer uw e-mailadres in","voer de verificatiecode in","Klik om te krijgen","Voer wachtwoord in alstublieft","wachtwoord opnieuw instellen"],
  footer: ["voorpagina", "hal", "Volgorde", "de mijne"],

  home: {
    label:["welkom"],
    broadcast: "Felicitaties aan de leden{member} worden{grade}",
    menu: ["Financieringslogboek", "Beginnerscursus", "Nodig registratie uit", "Contacteer de Klantenservice"],
    noticeTitle: "Laatste aankondiging",
    msg: "De taak is niet geopend",
    video: "Nog geen video-tutorial",
  },
  hall:{
    default:[
      "beursvloer","Evenwicht","ik wil kopen","ik wil verkopen","Bevestig verkoop",
      "Bestellijst","verkoop bestelling","verkopen aan handelaren","lopende bestelling","kopen","te koop gaan"
    ],
    list:[
      'hoeveelheid','eenheid prijs','Geen transactie','Verkoop USDT','Voer het USDT-bedrag in','Bedrag ophalen',"Quotum",'Koop USDT','Bedrag uitgegeven'
    ],
    kbip:["Annuleren","overtollig bedrag","Moet binnen het bedragbereik liggen"]
  },
  msglist:[
    "Berichtenlijst"
  ],
  sell:{
    placeholder:["Voer de verkoopprijs in","Voer het verkoopaantal in"],
    label:["Evenwicht(USDT)","Naam","bankrekening","Huidige prijs(USDT)","Beste verkoopprijs","verkoopprijs","Verkoop USDT-hoeveelheid","beste prijs","alle","Bevestig om te verkopen"]
  },
  buy:{
    placeholder:["Voer de aankoopprijs in","Voer het aankoopaantal in","Minimale hoeveelheid","Maximaal aantal"],
    label:["Evenwicht(USDT)","Naam","bankrekening","Huidige prijs(USDT)","beste prijs kopen","koopprijs","Koop hoeveelheid","beste prijs","alle","Bevestig aankoop","Quotum"]
  },
  user: {
    default: [
      "Persoonlijk centrum",
      "inlog account",
      "Uitnodigingscode",
      "afmelden",
      "Evenwicht",
      "goud",
      "portemonnee",
    ],
    menu: [
      "mijn bezittingen",
      "Teamlijst",
      "Accountbinding",
      "Accountgegevens",
      "Platformintroductie",
      "afmelden",
      "rente portemonnee",
    ],
  },
  bindAccount: {
    default: ["Account binden","indienen"],
    fields: ["natie/gebied", "SWIFT", "SORT CODE","banknaam","IBAN","accountnaam",'naam','achternaam','adres','postcode','Stad','provincie of staat',"mobiele telefoon"],
    placeholder: ["Voer het land in/gebied","Voer SWIFT in","Voer SORTEERCODE in","Voer de banknaam in","Vul IBAN in","Voer uw bankrekeningnummer in",
      "Voer uw voornaam in","Voer achternaam in","Voer het adres in","Voer postcode in","Voer de stad in","Voer provincie of staat in","Voer het telefoonnummer in"],
  },
  wallet: {
    default: [
      "mijn bezittingen",
      "Opladen",
      "terugtrekken",
      "Record opladen",
      "Opnameregistratie",
      "Opgeladen bedrag",
      "Aantal verkocht",
      "Verdiende inkomsten",
      "Rekeningsaldo",
      "Evenwicht",
      "Bedrag opwaarderen(USDT)",
      "Verkoopbedrag",
      "De totale omzet",
      "Upgrade handelaar",
      "Neem contact op met de klantenservice om uw verkoper te upgraden",
      "Bevestig dat handelaars zich hebben gevestigd",
      "Verkopercertificering",
      "je moet betalen","betaalbewijs","Handelaarsstorting","Nu verifiëren","Informatie","je moet betalen","Moet je opladen als het saldo onvoldoende is?"
    ],
    label: [
      "Opnamemethode",
      "Opnamebedrag",
      "Wachtwoord van het fonds",
      "indienen",
      "Telefoonnummer",
      "Mail",
      "IFSC",
      "Zeker",
    ],
    placeholder: [
      "Kies de opnamemethode",
      "Voer het opnamebedrag in",
      "Voer het wachtwoord van het fonds in",
      "Selecteer een opnamemethode",
      "Voer het mobiele telefoonnummer van de begunstigde in",
      "Voer het e-mailadres van de begunstigde in",
      "Voer de IFSC van de begunstigde in",
    ],
    msg: [
      "U heeft geen fondswachtwoord ingesteld. Stel dit eerst in",
      "U heeft uw PIX-account nog niet gekoppeld. Koppel dit eerst",
      "Bevestig om geld op te nemen?",
    ],
  },
  recharge: {
    default: [
      "Portemonnee opladen",
      "Portemonnee vooraf opladen",
      "Oplaadmethode",
      "Opladen",
      "bevestigen",
    ],
    label: [
      "categorie",
      "adres",
      "Bedrag opwaarderen",
      "Voer een bedrag in",
      "certificaat uploaden",
      "Kopiëren succesvol",
      "Omruilen vóór opladen"
    ],
    placeholder: [
      "type",
      "adres",
    ],
    info: [
      "Bedrag opwaarderen",
      "bestellingsnummer",
      "Begunstigde bank",
      "Verzamelrekening",
      "Begunstigde",
      "kopiëren",
    ]
  },
  task: {
    tabs: ["alle","bezig", "verlopen", "voltooid","Wachten op betaling door verkoper","Wachten op bevestiging"],
    default: ["Bestellijst",],
    msg: ["Review versturen", "Verzonden, wacht op beoordeling", "Inzending mislukt. Verzend opnieuw"],
  },
  userInfo: {
    default: [
      "persoonlijke informatie",
      "avatar",
      "rekeningnummer",
      "Mail",
      "Alipay",
      "Details",
      "verander wachtwoord",
      "Wachtwoord van het fonds",
      "Klik op instellingen",
      "Wijzig avatar",
      "Wijzig het inlogwachtwoord",
      "Wijzig het wachtwoord van het fonds",
      "indienen",
      "Maak de cache leeg",
      "Bekijk informatie",
      "Bijnaam",
      "Verwijzingscode"
    ],
    label: [
      "Origineel inlogwachtwoord",
      "Nieuw inlogwachtwoord",
      "bevestig wachtwoord",
      "Origineel fondswachtwoord",
      "Nieuw fondswachtwoord",
      "bevestig wachtwoord",
      "redden"
    ],
    placeholder: [
      "Voer het originele inlogwachtwoord in",
      "Voer een nieuw inlogwachtwoord in",
      "Bevestig het inlogwachtwoord",
      "Voer het originele fondswachtwoord in",
      "Voer een nieuw fondswachtwoord in",
      "Bevestig het wachtwoord van het fonds",
    ],
  },
  fundRecord: {
    default: ["Uitgavenregistratie", "Record opladen", "Activalogboek", "Aanval", "ontvangen", "tak"],
    tabs: ["inkomen", "uitgaven", "Opladen"],
  },
  dialog: [
    "hint",
    "Zeker",
    "is aan het indienen...",
    "Gekopieerd",
    "De IOS-systeemversie is laag en wordt niet ondersteund",
    "Registreren...",
    "bezig met laden...",
  ],
  serviceCenter: [
    "Klantenservice",
    "Hallo, ik ben een toegewijde klantenservice~",
    "Ik ben blij u te mogen dienen",
    "Zelfbediening",
    "Online klantenservice",
    "Klantenservice opladen",
    "Lijn klantenservice",
  ],
  userTaskRecord: [
    "Mijn bestelling",
    "verkoop bestelling",
    "bestelling kopen",
    "Huidige status",
    "commissie krijgen",
    "voltooid",
  ],
  withdrawlist: [
    "Opnameregistratie",
  ],
  teamReport: {
    default: [
      "Teamlijst",
    ],
  },
  common2:['Beginnerscursus',"Contacteer de Klantenservice","Servicevoorwaarden, bekijk details","Klantenservice 1","Klantenservice 2"],
  common3:['succes',"Platformintroductie"],
  invite:['Deel en promoot','mijn uitnodigingscode',"Kopieer de uitnodigingscode om meer vrienden uit te nodigen om mee te doen","kopiëren"],
  common4:['Geverifieerd',"Succesvol ingediend","Voorzorgsmaatregelen","Kwestie inhoud","Te betalen bedrag","ID-kaart voorkant",
    "Bewijs van financiën","Voltooi eerst de accountbinding","Voltooi eerst de authenticatie op echte naam"],
  common5:["verkopen","minuut","vooruit","QR-code opslaan","Open","Account bevroren, tijdelijk niet in staat om te handelen","APP-download"],
  common6:["Bestelling opwaarderen","bestellingsnummer","Hoeveelheid","tijd","staat"],
  hall2:["Het minimale verkoopbedrag bedraagt","","Het aantal verkopen overschrijdt de daglimiet"],
  register2:["email registratie","Registreer uw telefoon","Voer het telefoonnummer in"],
  withdraw2:["terugtrekken","Verkopersaccount","rekeningsaldo","Het te betalen bedrag","betaal onmiddellijk","Er zijn nog steeds taken die niet zijn voltooid en er kunnen voorlopig geen opnames worden gedaan.","Het aantal opnames per dag overschrijdt","handleiding"],
  mytask2:["Handelaarsaccount","prijs","Hoeveelheid","Rekeningsaldo","Het te betalen bedrag","Wedstrijdtijd","Bestel Details","Hoeveelheid kan niet 0 zijn","Onvoldoende evenwicht","Prijs kan niet 0 zijn","Bereikfout"],
  taskOrder2:["U heeft nog steeds niet-vervulde bestellingen, die niet kunnen worden geannuleerd.","Onvoldoende evenwicht","bevestigen","mislukking","U moet nog steeds de bestelling voltooien om geld op te nemen:"],
  busAuth2:['Verkoper-upgrade','Ik ben het eens',"Bevestig de upgrade","Upgrade handelaar","Als u uw bankkaartgegevens wilt wijzigen, kunt u contact opnemen met de klantenservice","Het huidige account moet worden geüpgraded naar een verkopersaccount"],
  recharge2:['Bedrag kan niet 0 zijn','Screenshots moeten worden geüpload'],
  buy3:["Aftellen","Uur","type",'Voordeel bestelling','gezamenlijke verkooporder','Beperk het aantal personen','Geen input, geen limiet op het aantal personen','Geef gebruikers-ID op','Voer het opgegeven ID in'],
  hall3:["lucht","Uur","minuut","Seconde","gezamenlijke verkooporder","rekeningnummer",'verlopen zijn'],
  sell3:['De verkoopprijs kan niet hoger zijn dan de beste prijs'],
  busAuth3:["lid","Handelaar","Kroonkoopman"],
  recharge3:["berekenen"],
  withdraw3:{
    fields:["Openingsnaam van de rekening", "telefoonnummer", "IBAN","banknaam","Bankcode"],
    placeholder:["Voer de openingsnaam van de rekening in","Voer het telefoonnummer in","Vul IBAN in","Voer de banknaam in","Selecteer bankcode"],
  },
  my2:["lucht","rente","rente portemonnee","Details"],
  fin:["Hoeveelheid","de voorwaarde","rente","Vervaldatum"]
};
