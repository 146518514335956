export default {
  mixRecharge: ["请填写邮箱地址"],
  language: "语言",
  common: ["在线客服", "取消悬浮","确认","取消"],
  upload: ["上传中...", "错误格式", "上传成功", "上传失败"],
  vanPull: ["没有更多数据了", "没有数据"],
  login: {
    text: ["语言","JMCP","登录"],
    label: ["正在登录...", "立即登录"],
    placeholder: ["请输入邮箱或者手机号", "请输入登录密码"],
    default: ["没有账号？", "立即注册","忘记密码?","忘记登录密码"],
    codes:["账号不存在","密码不正确","账号被冻结","失败"]
  },
  register: {
    text: [
      "JMCP",
      "验证码发送中...",
      "点击获取",
      "正在注册...",
      "立即注册",
    ],
    placeholder: [
      "请输入邮箱",
      "请输入验证码",
      "请输入登录密码",
      "请确认您的密码",
      "请输入邀请码",
      "两次密码不一样",
      "请输入验证码",
      "无效邮箱"
    ],
    label: ["已有账号？{a} {line}", "返回登录"],
    codes:["账号已存在","没有验证码","验证码不正确","两次密码不一致","推荐人不存在","失败"]
  },
  resetpwd:["忘记密码","请输入邮箱","请输入验证码","点击获取","请输入密码","重置密码"],
  footer: ["首页", "大厅", "订单", "我的"],

  home: {
    label:["欢迎"],
    broadcast: "恭喜会员{member} 成为{grade}",
    menu: ["资金日志", "新手教程", "邀请注册", "联系客服"],
    noticeTitle: "最新公告",
    msg: "任务未开放",
    video: "暂无视频教程",
  },
  hall:{
    default:[
        "交易大厅","余额","我要买","我要卖","确认卖出",
      "订单列表","卖出订单","卖给商家","挂单中","买入","去出售"
    ],
    list:[
        '数量','单价','未成交','出售USDT','请输入USDT数量','获得金额',"额度",'买入USDT','耗费金额'
    ],
    kbip:["撤销","超出金额","必须在金额范围内"]
  },
  msglist:[
    "消息列表"
  ],
  sell:{
      placeholder:["请输入卖出价格","请输入卖出数量"],
      label:["余额(USDT)","姓名","银行账号","当前价格(USDT)","卖出最优价格","卖出价格","卖出数量","最优价格","全部","确定卖出"]
  },
  buy:{
    placeholder:["请输入买入价格","请输入买入数量","最小额度","最大额度"],
    label:["余额(USDT)","姓名","银行账号","当前价格(USDT)","买入最优价格","买入价格","买入数量","最优价格","全部","确认买入","额度"]
  },
  user: {
    default: [
      "个人中心",
      "登录账号",
      "邀请码",
      "退出登录",
      "余额",
      "金币",
      "钱包",
    ],
    menu: [
      "我的资产",
      "团队列表",
      "账户绑定",
      "账户明细",
      "平台介绍",
      "退出登录",
    ],
  },
 bindAccount: {
    default: ["绑定账号","提交"],
    fields: ["国家/地区", "SWIFT", "SORT CODE","银行名称","IBAN","账户名",'名','姓','地址','邮编','城市','省或州'],
    placeholder: ["请输入国家/地区", "请输入SWIFT", "请输入SORT CODE","请输入银行名称","请输入IBAN","请输入账户名",'请输入名','请输入姓','请输入地址','请输入邮编','请输入城市','请输入省或州'],
  },
  wallet: {
    default: [
      "我的资产",
      "充值",
      "提现",
      "充值记录",
      "提现记录",
      "已充值金额",
      "已卖出金额",
      "已获取收益",
      "账号余额",
      "余额",
      "充值金额(USDT)",
      "卖出金额",
      "总收益",
        "升级商家",
      "升级商家，请联系客服",
        "确认入驻商家",
        "商家认证",
      "您需支付","支付凭证","商家保证金","立即认证","信息","您需支付","余额不足是否充值"
    ],
    label: [
      "提现方式",
      "提现金额",
      "资金密码",
      "提交",
      "手机号",
      "邮箱",
      "IFSC",
      "确定",
    ],
    placeholder: [
      "选择提现方式",
      "请输入提现金额",
      "请输入资金密码",
      "请选择提现方式",
      "请输入收款人手机号",
      "请输入收款人邮箱",
      "请输入收款人IFSC",
    ],
    msg: [
      "你还未设置资金密码，请先设置",
      "你还未绑定PIX账户，请先绑定",
      "确定要提现?",
    ],
  },
  recharge: {
    default: [
      "钱包充值",
      "钱包预充值",
      "充值方式",
        "充值",
        "确认",
    ],
    label: [
      "类别",
      "地址",
      "充值金额",
      "输入金额",
      "上传凭证",
        "拷贝成功",
        "预充值交易所"
    ],
    placeholder: [
      "类型",
      "地址",
    ],
    info: [
      "充值金额",
      "订单号",
      "收款银行",
      "收款账号",
      "收款人",
      "复制",
    ]
  },
  task: {
    tabs: ["全部","进行中", "已失效", "已完成","等待商家支付","等待确认"],
    default: ["订单列表",],
    msg: ["提交审核", "已提交，请等待审核", "提交失败，请重新提交"],
  },
  userInfo: {
    default: [
      "个人信息",
      "头像",
      "账号",
      "邮箱",
      "支付宝",
      "详细信息",
      "修改密码",
      "资金密码",
      "点击设置",
      "修改头像",
      "修改登录密码",
      "修改资金密码",
      "提交",
      "清空缓存",
      "查看信息",
      "昵称",
      "推荐码"
    ],
    label: [
      "原登录密码",
      "新登录密码",
      "确认密码",
      "原资金密码",
      "新资金密码",
      "确认密码",
      "保存"
    ],
    placeholder: [
      "请输入原登录密码",
      "请输入新登录密码",
      "请确认登录密码",
      "请输入原资金密码",
      "请输入新资金密码",
      "请确认资金密码",
    ],
  },
  fundRecord: {
    default: ["支出记录", "充值记录", "资产日志", "充", "收", "支"],
    tabs: ["收入", "支出", "充值"],
  },
 dialog: [
    "提示",
    "确定",
    "正在提交...",
    "复制成功",
    "IOS系统版本低不支持",
    "正在注册...",
    "数据加载中...",
  ],
  serviceCenter: [
    "客服中心",
    "Hi,我是专属客服~",
    "很高兴为您服务",
    "自助服务",
    "在线客服",
    "充值客服",
    "Line客服",
  ],
 userTaskRecord: [
    "我的订单",
    "卖出订单",
    "买入订单",
    "当前状态",
    "获得佣金",
    "已完成",
  ],
  withdrawlist: [
    "提现记录",
  ],
  teamReport: {
    default: [
      "团队列表",
    ],
  },
  common2:['新手教程',"联系客服","服务条款，查看详情","客服1","客服2"],
  common3:['成功',"平台介绍"],
  invite:['分享推广','我的邀请码',"复制邀请码，邀请更多好友加入","复制","暫時無法邀請會員"],
  common4:['实名认证',"提交成功","注意事项","事项内容","缴纳金额","身份证正面",
      "财务证明","请先去完成账号绑定","请先完成实名认证"],
  common5:["卖出","分钟","前","保存二维码","打开","账号冻结,暂时无法交易","APP下载"],
  common6:["充值订单","订单号","金额","时间","状态"],
  hall2:["最低出售金额为","","卖出次数超出每天限制"],
  register2:["邮箱注册","手机注册","请输入手机号码"],
  withdraw2:["提现","卖家账号","账户余额","需支付金额","立即支付","还有任务未完成,暂时不能提现","每天提现次数超出","备注说明"],
  mytask2:["商家账号","价格","金额","账号余额","需付款金额","匹配时间","订单详情","数量不能为0","余额不足","价格不能为0","范围错误"],
  taskOrder2:["你还有未成交订单，不能撤销","余额不足","确认","失败","你还需要完成订单才能提现:"],
    busAuth2:['商家升级','我同意',"确认升级","升级商家","需要修改银行卡信息请联系客服","当前账号需要升级为商家账号"],
 recharge2:['金额不能为0','必须上传截图'],
  buy3:["cuenta atrás", "hora", "tipo",'orden de beneficio','orden combinada','limitar el número de personas','no introducir un número ilimitado de personas','especificar id de usuario','por favor introduzca id especificado'],
  hall3:['días','horas','minutos','segundos','pedidos combinados','cuenta','expirado'],
  sell3:['El precio de venta no puede ser mayor que el mejor precio'],
  busAuth3:["Miembro", "Comerciante", "Comerciante de la Corona"],
  recharge3:["calcular"],
  withdraw3:{
    fields:["Nombre de la cuenta abierta", "Número de móvil", "IBAN", "Nombre del banco", "Código bancario"],
    placeholder:["Por favor, introduzca su nombre de cuenta", "Por favor, introduzca su número de móvil", "Por favor, introduzca el IBAN", "Por favor, introduzca el nombre de su banco", "Por favor, seleccione su código bancario"],
  },
  my2:["Días","Tasa de interés","InterésCartera"]
};
