export default {
  mixRecharge: ["Bitte geben Sie Ihre E-Mail-Adresse ein"],
  language: "Sprache",
  common: ["Onlineservice", "Suspendierung aufheben","bestätigen","Stornieren"],
  upload: ["Hochladen...", "falsches Format", "erfolgreich hochgeladen", "Upload fehlgeschlagen"],
  vanPull: ["keine Daten mehr", "keine Daten"],
  login: {
    text: ["Sprache","JMCP","Anmeldung"],
    label: ["Einloggen...", "sofort einloggen"],
    placeholder: ["Bitte geben Sie Ihre E-Mail oder Telefonnummer ein", "Bitte geben Sie Ihr Passwort ein"],
    default: ["Kein Account？", "Jetzt registrieren","Passwort vergessen?","Haben Sie Ihr Passwort vergessen"],
    codes:["Konto existiert nicht","Falsches Passwort","Konto ist eingefroren","scheitern"]
  },
  register: {
    text: [
      "JMCP",
      "Bestätigungscode wird gesendet...",
      "Klicken Sie, um zu erhalten",
      "Registrieren...",
      "Jetzt registrieren",
    ],
    placeholder: [
      "Bitte geben Sie Ihre E-Mail ein",
      "Bitte geben Sie den Bestätigungscode ein",
      "Bitte geben Sie Ihr Passwort ein",
      "Bitte bestätigen Sie Ihr Passwort",
      "Bitte geben Sie den Einladungscode ein",
      "zwei verschiedene Passwörter",
      "Bitte geben Sie den Bestätigungscode ein",
      "ungültige E-Mail"
    ],
    label: ["Sie haben bereits ein Konto？{a} {line}", "wieder einloggen"],
    codes:["Konto existiert bereits","kein Bestätigungscode","Falscher verifikationscode","Die zwei Passwörter stimmen nicht überein","Referrer existiert nicht","scheitern"]
  },
  resetpwd:["Passwort vergessen","Bitte geben Sie Ihre E-Mail ein","Bitte geben Sie den Bestätigungscode ein","Klicken Sie, um zu erhalten","Bitte Passwort eingeben","Passwort zurücksetzen"],
  footer: ["Titelseite", "Saal", "Befehl", "Mine"],

  home: {
    label:["Willkommen"],
    broadcast: "Herzlichen Glückwunsch Mitglied{member} werden{grade}",
    menu: ["Fondsprotokoll", "Anfänger-Tutorial", "Einladung zur Anmeldung", "Wenden Sie sich an den Kundendienst"],
    noticeTitle: "Neueste Ankündigung",
    msg: "Mission nicht geöffnet",
    video: "Kein Video-Tutorial",
  },
  hall:{
    default:[
        "Handelsraum","Gleichgewicht","ich möchte kaufen","Ich möchte verkaufen","Verkauf bestätigen",
      "Bestellliste","Auftrag verkaufen","an Händler verkaufen","Ausstehende Bestellung","besorgen","verkaufen"
    ],
    list:[
        'Menge','Einzelpreis','Unverkauft','USDT verkaufen','Bitte geben Sie den USDT-Betrag ein','den Betrag bekommen',"Quote",'USDT kaufen','Der bezahlte Betrag'
    ],
    kbip:["widerrufen","Überschuß","muss innerhalb des Betrags liegen"]
  },
  msglist:[
    "Nachrichtenliste"
  ],
  sell:{
      placeholder:["Bitte geben Sie den Verkaufspreis ein","Bitte geben Sie die Verkaufsmenge ein"],
      label:["Gleichgewicht(USDT)","Name","Bankkonto","Derzeitiger Preis(USDT)","Bestpreis verkaufen","Verkaufspreis","Menge verkaufen","Bestpreis","alle","OK zu verkaufen"]
  },
  buy:{
    placeholder:["Bitte geben Sie den Kaufpreis ein","Bitte geben Sie die Abnahmemenge ein","Mindestbetrag","Höchstbetrag"],
    label:["Gleichgewicht(USDT)","Name","Bankkonto","Derzeitiger Preis(USDT)","besten preis kaufen","Kaufpreis","Menge kaufen","Bestpreis","alle","Kauf bestätigen","Quote"]
  },
  user: {
    default: [
      "persönliches Zentrum",
      "Login Account",
      "Einladungscode",
      "austragen",
      "Gleichgewicht",
      "Gold",
      "Geldbörse",
    ],
    menu: [
      "mein Vermögen",
      "Mannschaftsliste",
      "Kontobindung",
      "Kontodetails",
      "Plattform-Einführung",
      "austragen",
    ],
  },
 bindAccount: {
    default: ["Konto binden","einreichen"],
    fields: ["Nation/Bereich", "SWIFT", "SORT CODE","Bank Name","IBAN","Kontobezeichnung",'Name','Nachname','Adresse','Postleitzahl','Stadt','Provinz oder Staat'],
    placeholder: ["Bitte Land eingeben/Bereich", "Bitte geben Sie SWIFT ein", "Bitte SORT CODE eingeben","Bitte geben Sie den Banknamen ein","Bitte IBAN eingeben","Bitte geben Sie Ihren Benutzernamen ein",'Bitte geben Sie den Vornamen ein','Bitte geben Sie einen Nachnamen ein','Bitte Adresse eingeben','Bitte geben Sie eine Postleitzahl ein','Bitte geben Sie eine Stadt ein','Bitte geben Sie eine Provinz oder ein Bundesland ein'],
  },
  wallet: {
    default: [
      "mein Vermögen",
      "aufladen",
      "zurückziehen",
      "Rekord aufladen",
      "Aufzeichnung der Auszahlungen",
      "Aufgeladener Betrag",
      "Verkaufte Menge",
      "Gewonnen",
      "Kontostand",
      "Gleichgewicht",
      "Aufladebetrag(USDT)",
      "Verkaufsbetrag",
      "Gesamteinnahmen",
        "Geschäft aufrüsten",
      "Geschäft aufrüsten，Bitte wenden Sie sich an den Kundendienst",
        "Erledigtes Geschäft bestätigen",
        "Händlerauthentifizierung",
      "du musst bezahlen","Zahlungsbeleg","Händlereinzahlung","Authentifizieren Sie sich jetzt","Information","du musst bezahlen","Ob aufgeladen werden soll, wenn das Guthaben nicht ausreicht"
    ],
    label: [
      "Auszahlungsmethode",
      "Auszahlungsbetrag",
      "Passwort für den Fonds",
      "einreichen",
      "Telefonnummer",
      "Post",
      "IFSC",
      "Sicher",
    ],
    placeholder: [
      "Wählen Sie eine Auszahlungsmethode",
      "Bitte geben Sie den Auszahlungsbetrag ein",
      "Bitte geben Sie das Fondspasswort ein",
      "Bitte wählen Sie eine Auszahlungsmethode",
      "Bitte geben Sie die Handynummer des Zahlungsempfängers ein",
      "Bitte geben Sie die E-Mail-Adresse des Empfängers ein",
      "Bitte geben Sie den IFSC des Begünstigten ein",
    ],
    msg: [
      "Sie haben kein Fondspasswort festgelegt，Bitte zuerst einrichten",
      "Sie haben das PIX-Konto nicht gebunden，Bitte erst binden",
      "Rückzug bestätigen?",
    ],
  },
  recharge: {
    default: [
      "Brieftasche aufladen",
      "Wallet-Vorbelastung",
      "Auflademethode",
        "aufladen",
        "bestätigen",
    ],
    label: [
      "Kategorie",
      "Adresse",
      "Aufladebetrag",
      "Geben Sie den Betrag ein",
      "Zertifikat hochladen",
        "erfolgreich kopieren",
        "Austausch vor der Einzahlung"
    ],
    placeholder: [
      "Typ",
      "Adresse",
    ],
    info: [
      "Aufladebetrag",
      "Bestellnummer",
      "Begünstigte Bank",
      "Inkassokonto",
      "Zahlungsempfänger",
      "Kopieren",
    ]
  },
  task: {
    tabs: ["alle","im Gange", "Abgelaufen", "vollendet","Warten auf die Zahlung des Händlers","Auf Bestätigung warten"],
    default: ["Bestellliste",],
    msg: ["Bewertung abschicken", "eingereicht，Bitte warten Sie auf die Überprüfung", "Übermittlung fehlgeschlagen，bitte erneut einreichen"],
  },
  userInfo: {
    default: [
      "Persönliche Angaben",
      "Benutzerbild",
      "Konto",
      "Post",
      "Alipay",
      "Einzelheiten",
      "Kennwort ändern",
      "Passwort für den Fonds",
      "klicken Sie auf Einstellungen",
      "Avatar ändern",
      "Login-Passwort ändern",
      "Fondspasswort ändern",
      "einreichen",
      "Leeren Sie den Cache",
      "Informationen anzeigen",
      "Spitzname",
      "Referenzcode"
    ],
    label: [
      "ursprüngliches Login-Passwort",
      "Neues Login-Passwort",
      "Bestätige das Passwort",
      "Ursprüngliches Fondspasswort",
      "neues Fondspasswort",
      "Bestätige das Passwort",
      "speichern"
    ],
    placeholder: [
      "Bitte geben Sie das ursprüngliche Login-Passwort ein",
      "Bitte geben Sie ein neues Login-Passwort ein",
      "Bitte bestätigen Sie das Login-Passwort",
      "Bitte geben Sie das Original-Fondspasswort ein",
      "Bitte geben Sie das neue Fondspasswort ein",
      "Bitte bestätigen Sie das Fondspasswort",
    ],
  },
  fundRecord: {
    default: ["Spesenabrechnung", "Rekord aufladen", "Vermögensprotokoll", "Aufladung", "erhalten", "Zweig"],
    tabs: ["Einkommen", "Ausgaben", "aufladen"],
  },
 dialog: [
    "Hinweis",
    "Sicher",
    "einreicht...",
    "erfolgreich kopieren",
    "Niedrige Version des IOS-Systems wird nicht unterstützt",
    "Registrieren...",
    "Wird geladen...",
  ],
  serviceCenter: [
    "Kundendienst",
    "Hi,Ich bin exklusiver Kundenservice~",
    "Ich freue mich, Ihnen zu dienen",
    "Selbstbedienung",
    "Onlineservice",
    "Kundenservice aufladen",
    "LineKundendienst",
  ],
 userTaskRecord: [
    "Meine Bestellung",
    "Auftrag verkaufen",
    "Bestellung kaufen",
    "aktueller Status",
    "Provision bekommen",
    "vollendet",
  ],
  withdrawlist: [
    "Aufzeichnung der Auszahlungen",
  ],
  teamReport: {
    default: [
      "Mannschaftsliste",
    ],
  },
  common2:['Anfänger-Tutorial',"Wenden Sie sich an den Kundendienst","Nutzungsbedingungen, Details anzeigen","Kundendienst 1","Kundendienst 2"],
  common3:['Erfolg',"Plattform-Einführung"],
  invite:['Förderung teilen','mein Einladungscode',"Einladungscode kopieren，Laden Sie weitere Freunde zur Teilnahme ein","Kopieren","Mitglieder können vorübergehend nicht eingeladen werden"],
  common4:['Verifiziert',"erfolgreich eingereicht","Vorsichtsmaßnahmen","Artikelinhalt","Zahlungsbetrag","Ausweis vorne",
      "finanzielle Bescheinigung","Bitte schließen Sie zuerst die Kontobindung ab","Bitte führen Sie zuerst die Realname-Authentifizierung durch"],
  common5:["verkaufen","Minute","nach vorne","Speichern Sie den QR-Code","Offen","Das Konto ist eingefroren und kann vorübergehend nicht gehandelt werden","APP-Download"],
  common6:["Bestellung aufladen","Bestellnummer","die Summe","Zeit","Zustand"],
  hall2:["Der Mindestverkaufsbetrag beträgt","","Die Anzahl der Verkäufe überschreitet das Tageslimit"],
  register2:["E-Mail Registrierung","Registrieren Sie Ihr Telefon","Bitte geben Sie die Telefonnummer ein"],
  withdraw2:["zurückziehen","Verkäuferkonto","Kontostand","Zu bezahlende Summe","sofort bezahlen","Es gibt noch unerledigte Aufgaben, die vorübergehend kein Bargeld abheben können","Die Anzahl der Abhebungen pro Tag übersteigt","Bedienungsanleitung"],
  mytask2:["Händler-Konto","Preis","die Summe","Kontostand","Zu zahlender Betrag","Spielzeit","Bestelldetails","Menge darf nicht 0 sein","Mangelhaftes Gleichgewicht","Der Preis darf nicht 0 sein","Reichweitenfehler"],
  taskOrder2:["Sie haben noch ausstehende Bestellungen, die nicht storniert werden können","Mangelhaftes Gleichgewicht","bestätigen","scheitern","Sie müssen die Bestellung noch abschließen, um Bargeld abzuheben:"],
    busAuth2:['Händler-Upgrade','Ich stimme zu',"Upgrade bestätigen","Geschäft aufrüsten","Wenn Sie die Bankkarteninformationen ändern müssen, wenden Sie sich bitte an den Kundendienst","Das Girokonto muss auf ein Händlerkonto hochgestuft werden"],
 recharge2:['Betrag darf nicht 0 sein','Screenshots müssen hochgeladen werden'],
  buy3:["Countdown", "Stunde", "Kerl",'Gewinnordnung','kombiniert bestellen','die Personenzahl begrenzen','Geben Sie nicht eine unbegrenzte Anzahl von Personen ein','Benutzer-ID angeben','Bitte geben Sie die angegebene ID ein'],
  hall3:['Tage','Std.','Protokoll','Sekunden','kombinierte Bestellungen','Konto','Abgelaufen'],
  sell3:['Der Verkaufspreis darf nicht höher sein als der Bestpreis'],
  busAuth3:["Mitglied", "Geschäftsmann", "Kronenhändler"],
  recharge3:["Berechnung"],
  withdraw3:{
    fields:["Kontoname öffnen", "Telefonnummer", "WIR GEHEN", "Name der Bank", "Bankleitzahl"],
    placeholder:["Bitte, Geben Sie Ihren Kontonamen ein", "Bitte geben sie ihre Mobiltelefonnummer ein", "Bitte geben Sie die IBAN ein", "Bitte geben Sie den Namen Ihrer Bank ein", "Bitte wählen Sie Ihre Bankleitzahl aus"],
  },
  my2:["Tage","Zinsrate","Zinsportfolio"]
};
