export default {
  mixRecharge: ["Por favor, preencha seu endereço de e-mail"],
  language: "linguagem",
  common: ["serviço on-line", "Cancelar suspensão","confirme","Cancelar"],
  upload: ["Enviando...", "Formato incorreto", "Carregamento bem-sucedido", "falha no upload"],
  vanPull: ["Não há mais dados", "sem dados"],
  login: {
    text: ["linguagem","Crypto","Conecte-se"],
    label: ["fazendo login...", "faça login imediatamente"],
    placeholder: ["Por favor insira seu e-mail ou número de celular", "Por favor, insira sua senha"],
    default: ["Sem conta?", "Inscreva-se agora","esqueça a senha?","Esqueceu sua senha"],
    codes:["conta não existe","Senha incorreta","A conta está congelada","falhar"]
  },
  register: {
    text: [
      "Crypto",
      "O código de verificação está sendo enviado...",
      "Clique para obter",
      "Registrando...",
      "Inscreva-se agora",
    ],
    placeholder: [
      "por favor insira seu e-mail",
      "insira o código de verificação",
      "Por favor, insira sua senha",
      "Por favor confirme sua senha",
      "Por favor insira o código do convite",
      "As duas senhas são diferentes",
      "insira o código de verificação",
      "E-mail inválido"
    ],
    label: ["já tem uma conta?{a} {line}", "Voltar ao login"],
    codes:["essa conta já existe","Nenhum código de verificação","Código de verificação incorreto","Duas senhas são inconsistentes","O recomendador não existe","falhar"]
  },
  resetpwd:["esqueça a senha","por favor insira seu e-mail","insira o código de verificação","Clique para obter","Por favor insira a senha","redefinir senha"],
  footer: ["primeira página", "salão", "Ordem", "meu"],

  home: {
    label:["Bem-vindo"],
    broadcast: "Parabéns aos membros{member} tornar-se{grade}",
    menu: ["Registro de financiamento", "Tutorial para iniciantes", "Registro de convite", "Entre em contato com o atendimento ao cliente"],
    noticeTitle: "Último anúncio",
    msg: "A tarefa não está aberta",
    video: "Ainda não há vídeo tutorial",
  },
  hall:{
    default:[
      "pregão","equilíbrio","eu quero vender","eu quero vender","Confirmar venda",
      "Lista de pedidos","pedido de venda","vender para comerciantes","pedido pendente","comprar","ir à venda"
    ],
    list:[
      'quantidade','preço unitário','Nenhuma transação','Vender USDT','Insira o valor em USDT','Obter valor',"Contingente",'Comprar USDT','Quantia gasta'
    ],
    kbip:["Cancelar","montante excessivo","Deve estar dentro da faixa de valor"]
  },
  msglist:[
    "Lista de mensagens"
  ],
  sell:{
    placeholder:["Por favor insira o preço de venda","Por favor insira a quantidade de venda"],
    label:["equilíbrio(USDT)","Nome","conta bancária","Preço atual (USDT)","Melhor preço de venda","preço de venda","Vender quantidade de USDT","melhor preço","todos","Confirme para vender"]
  },
  buy:{
    placeholder:["Por favor insira o preço de compra","Por favor insira a quantidade de compra","Quantidade mínima","Quantia máxima"],
    label:["equilíbrio(USDT)","Nome","conta bancária","Preço atual(USDT)","comprar melhor preço","preço de compra","Comprar quantidade","melhor preço","todos","Confirmar compra","Contingente"]
  },
  user: {
    default: [
      "Centro pessoal",
      "conta de login",
      "Código de Convite",
      "sair",
      "equilíbrio",
      "ouro",
      "carteira",
    ],
    menu: [
      "meus bens",
      "Lista de equipes",
      "Vinculação de conta",
      "Detalhes da conta",
      "Introdução à plataforma",
      "sair",
      "carteira de juros",
    ],
  },
  bindAccount: {
    default: ["Vincular conta","enviar"],
    fields: ["país/região", "SWIFT", "SORT CODE","nome do banco","IBAN","nome da conta",'nome','sobrenome','endereço','código postal','Cidade','província ou estado',"celular"],
    placeholder: ["Por favor insira o país/área","Por favor insira SWIFT","Por favor insira o CÓDIGO DE CLASSIFICAÇÃO","Por favor insira o nome do banco","Por favor insira o IBAN","Insira o número da conta bancária",
      "Por favor insira o primeiro nome","Por favor insira o sobrenome","Por favor insira o endereço","Por favor insira o CEP","Por favor insira a cidade","Por favor insira província ou estado","Por favor insira o número de telefone"],
  },
  wallet: {
    default: [
      "meus bens",
      "Recarrega",
      "retirar",
      "Registro de recarga",
      "Registro de retiradas",
      "Valor recarregado",
      "Quantidade vendida",
      "Receita obtida",
      "Saldo da conta",
      "equilíbrio",
      "Valor de recarga(USDT)",
      "Valor de venda",
      "Rendimento total",
      "Atualizar comerciante",
      "Para atualizar seu comerciante, entre em contato com o atendimento ao cliente",
      "Confirme os comerciantes instalados",
      "Certificação de comerciante",
      "você precisa pagar","comprovante de pagamento","Depósito do comerciante","Verifique agora","Informação","você precisa pagar","Você deve recarregar se o saldo for insuficiente?"
    ],
    label: [
      "Método de retirada",
      "Quantidade retirada",
      "Senha do fundo",
      "enviar",
      "Número de telefone",
      "Correspondência",
      "IFSC",
      "Claro",
    ],
    placeholder: [
      "Escolha o método de retirada",
      "Por favor insira o valor do saque",
      "Por favor insira a senha do fundo",
      "Selecione um método de retirada",
      "Insira o número do celular do beneficiário",
      "Insira o endereço de e-mail do beneficiário",
      "Insira o IFSC do beneficiário",
    ],
    msg: [
      "Você não definiu uma senha de fundo, defina-a primeiro",
      "Você ainda não vinculou sua conta PIX, vincule-a primeiro",
      "Confirme para sacar dinheiro?",
    ],
  },
  recharge: {
    default: [
      "Recarga de carteira",
      "Pré-recarga da carteira",
      "Método de recarga",
      "Recarrega",
      "confirme",
    ],
    label: [
      "categoria",
      "endereço",
      "Valor de recarga",
      "Insira o valor",
      "carregar certificado",
      "Cópia bem-sucedida",
      "Troca pré-recarga"
    ],
    placeholder: [
      "tipo",
      "endereço",
    ],
    info: [
      "Valor de recarga",
      "número do pedido",
      "Banco beneficiário",
      "Conta de cobrança",
      "Beneficiário",
      "cópia de",
    ]
  },
  task: {
    tabs: ["todos","em andamento", "expirado", "concluído","Aguardando pagamento do comerciante","Aguardando confirmação"],
    default: ["Lista de pedidos",],
    msg: ["Enviar revisão", "Enviado, aguarde análise", "Falha no envio, reenvie"],
  },
  userInfo: {
    default: [
      "informações pessoais",
      "avatar",
      "número de conta",
      "Correspondência",
      "Alipay",
      "Detalhes",
      "alterar a senha",
      "Senha do fundo",
      "Clique em configurações",
      "Modificar avatar",
      "Alterar senha de login",
      "Alterar senha do fundo",
      "enviar",
      "Esvaziar o cache",
      "Ver informações",
      "Apelido",
      "Código de Referencia"
    ],
    label: [
      "Senha de login original",
      "Nova senha de login",
      "Confirme sua senha",
      "Senha original do fundo",
      "Nova senha do fundo",
      "Confirme sua senha",
      "salvar"
    ],
    placeholder: [
      "Por favor insira a senha de login original",
      "Por favor insira a nova senha de login",
      "Por favor confirme a senha de login",
      "Por favor, insira a senha original do fundo",
      "Por favor, insira a nova senha do fundo",
      "Por favor, confirme a senha do fundo",
    ],
  },
  fundRecord: {
    default: ["Registros de despesas", "Registro de recarga", "Registro de ativos", "Cobrar", "receber", "filial"],
    tabs: ["renda", "despesa", "Recarrega"],
  },
  dialog: [
    "dica",
    "Claro",
    "está enviando...",
    "Copiado com sucesso",
    "A versão do sistema IOS é baixa e não é compatível",
    "Registrando...",
    "carregando...",
  ],
  serviceCenter: [
    "Atendimento ao Cliente",
    "Olá, sou um atendimento ao cliente dedicado ~",
    "Prazer em atendê-lo",
    "self-service",
    "Atendimento ao cliente on-line",
    "Recarregue o atendimento ao cliente",
    "Linha de atendimento ao cliente",
  ],
  userTaskRecord: [
    "Meu pedido",
    "pedido de venda",
    "pedido de compra",
    "Status atual",
    "receber comissão",
    "concluído",
  ],
  withdrawlist: [
    "Registro de retiradas",
  ],
  teamReport: {
    default: [
      "Lista de equipes",
    ],
  },
  common2:['Tutorial para iniciantes',"Entre em contato com o atendimento ao cliente","Termos de Serviço, ver detalhes","Atendimento ao cliente 1","Atendimento ao cliente 2"],
  common3:['sucesso',"Introdução à plataforma"],
  invite:['Compartilhe e promova','meu código de convite',"Copie o código de convite para convidar mais amigos para participar","cópia de"],
  common4:['Verificado',"submetido com sucesso","Precauções","Conteúdo da matéria","Valor do pagamento","frente do cartão de identificação",
    "Prova de finanças","Conclua a vinculação da conta primeiro","Por favor, complete a autenticação de nome real primeiro"],
  common5:["vender","minuto","avançar","Salvar código QR","Abrir","Conta congelada, temporariamente incapaz de negociar","Baixar APLICATIVO"],
  common6:["Pedido de recarga","número do pedido","Quantia","tempo","estado"],
  hall2:["O valor mínimo de venda é","","O número de vendas excede o limite diário"],
  register2:["Registro de email","Registre seu telefone","Por favor insira o número de telefone"],
  withdraw2:["retirar","Conta do vendedor","saldo da conta","Montante a ser pago","pague imediatamente","Ainda existem tarefas que não foram concluídas e os saques não podem ser feitos por enquanto.","O número de saques por dia ultrapassa","manual de instruções"],
  mytask2:["Conta de comerciante","preço","Quantia","Saldo da conta","Montante a ser pago","Hora da partida","detalhes do pedido","A quantidade não pode ser 0","Saldo insuficiente","O preço não pode ser 0","Erro de intervalo"],
  taskOrder2:["Você ainda tem pedidos não atendidos, que não podem ser cancelados.","Saldo insuficiente","confirme","falhar","Você ainda precisa concluir o pedido para sacar dinheiro:"],
  busAuth2:['Atualização do comerciante','Concordo',"Confirmar atualização","Atualizar comerciante","Se você precisar modificar as informações do seu cartão bancário, entre em contato com o atendimento ao cliente","A conta corrente precisa ser atualizada para uma conta de comerciante"],
  recharge2:['O valor não pode ser 0','As capturas de tela devem ser enviadas'],
  buy3:["Contagem regressiva","Hora","tipo",'Pedido de benefício','pedido de venda conjunta','Limite o número de pessoas','Sem entrada, sem limite de número de pessoas','Especifique o ID do usuário','Por favor insira o ID especificado'],
  hall3:["céu","Hora","minuto","Segundo","pedido de venda conjunta","número de conta",'estar expirado'],
  sell3:['O preço de venda não pode ser superior ao melhor preço'],
  busAuth3:["membro","Comerciante","Comerciante da Coroa"],
  recharge3:["calcular"],
  withdraw3:{
    fields:["Nome de abertura da conta", "número de telefone", "IBAN","nome do banco","Código bancário"],
    placeholder:["Por favor insira o nome de abertura da conta","Por favor insira o número de telefone","Por favor insira o IBAN","Por favor insira o nome do banco","Selecione o código do banco"],
  },
  my2:["céu","taxa de juro","carteira de juros","Detalhes"],
  fin:["Quantia","O termo","taxa de juro","Data de validade"]
};
