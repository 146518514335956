export default {
  mixRecharge: ["vui lòng điền địa chỉ email"],
  language: "ngôn ngữ",
  common: ["chăm sóc khách hàng online", "hủy bỏ treo","xác nhận","hủy bỏ"],
  upload: ["đang tải lên...", "quy cách sai", "tải lên thành công", "tải lên thất bại"],
  vanPull: ["không có thêm số liệu", "không có số liệu"],
  login: {
    text: ["ngôn ngữ","JMCP","đăng nhập"],
    label: ["đang đăng nhập...", "lập tức đăng nhập"],
    placeholder: ["vui long điền email", "Vui lòng điền mật khẩu đăng nhập"],
    default: ["chưa có tài khoản？", "Đăng ký ngay","quên mật khẩu?","quên mật khẩu đăng nhập"],
    codes:["Tài khoản không tồn tại","Mật khẩu sai","Tài khoản bị đóng băng","thất bại"]
  },
  register: {
    text: [
      "JMCP",
      "đang gửi mã xác nhận...",
      "ấn để nhận",
      "đang đăng ký...",
      "đăng ký ngay",
    ],
    placeholder: [
      "vui lòng nhập email",
      "vui lòng nhập mã xác nhận",
      "vui lòng nhập mật khẩu đăng nhập",
      "vui lòng nhập mật khẩu của bạn",
      "vui lòng nhập mã giới thiệu",
      "mật khẩu hai lần không giống nhau",
      "vui lòng nhập mã xác nhận",
      "email không có hiệu lực"
    ],
    label: ["đã có tài khoản？{a} {line}", "quay lại đăng nhập"],
    codes:["Tài khoản đã tồn tại","Chưa có mã xác minh","Mã xác minh không chính xác","2 lần mật khẩu không giống nhau","Chuẩn không tồn tại","thất bại"]
  },
  resetpwd:["quên mật khẩu","vui lòng nhập email","vui lòng nhập mã xác nhận","ấn để nhận","vui lòng nhập mật khẩu","cài đặt mật khẩu"],
  footer: ["trang chủ", "sảnh", "đơn hàng", "của tôi"],
  home: {
    label:["hoan nghênh"],
    broadcast: "chúc mừng thành viên{member} trở thành{grade}",
    menu: ["nhật ký tiền vốn", "hướng dẫn", "mời đăng ký", "dịch vụ khách"],
    noticeTitle: "thông báo mới nhất",
    msg: "nhiệm vụ chưa mở",
    video: "tạm thời chưa có video hướng dẫn",
  },
  hall:{
    default:[
        "sảnh giao dịch","số dư","tôi muốn mua","tôi muốn bán","xác nhận bán ra",
      "danh sách đơn hàng","đơn hàng bán ra","bán cho thương gia","đang rút đơn","mua vào ","đi bán ra"
    ],
    list:[
        'Số lượng','đơn giá','chưa xong thủ tục mua bán','bán ra USDT','vui lòng điền số lượng USDT','số tiền nhận được',"mức tiền",'mua vào USDT','số tiền tiêu hao'
    ],
    kbip:["bãi bỏ","vượt qua số tiền","nhất định phải trong số tiền quy định"]
  },
  msglist:[
    "danh sách thông tin"
  ],
  sell:{
      placeholder:["vui lòng điền giá tiền bán ra","vui lòng điền số lượng bán ra"],
      label:["số dư(USDT)","họ tên","tài khoản ngân hàng","giá tiền trước đó(USDT)","giá tiền bán ra ưu đãi nhất","giá tiền bán ra","số lượng bán ra","giá tiền ưu đãi nhất","toàn bộ","xác nhận bán ra"]
  },
  buy:{
    placeholder:["Vui lòng điền số tiền mua vào","vui lòng điên số lượng mua vào","mức tiền nhỏ nhất","mức tiền lớn nhất"],
    label:["số dư(USDT)","họ tên","tài khoản ngân hàng","giá tiền trước đó(USDT)","giá tiền mua vào ưu đãi nhất","giá tiền mua vào","số lượng mua vào","Giá tiền ưu đãi nhất","toàn bộ","xác nhận mua vào","mức tiền"]
  },
  user: {
    default: [
      "Trung tâm cá nhân",
      "Tài khoản đăng nhập",
      "Mã giới thiệu",
      "Thoát ra đăng nhập",
      "Số dư",
      "số tiền tệ",
      "ví tiền",
    ],
    menu: [
      "Tài sản của tôi",
      "Danh sách nhóm",
      "Ràng buộc tài khoản",
      "Chi tiết tài khoản",
      "Giới thiệu nền tảng",
      "Thoát ra đăng nhập",
    ],
  },
 bindAccount: {
    default: ["Ràng buộc tài khoản ngân hàng","Xác nhận giao nộp"],
    fields: ["họ tên mở tài khoản", "Số điện thoại", "tài khoản ngân hàng","Tên ngân hàng","loại","địa chỉ USDTl"],
    placeholder: ["vui lòng nhập họ tên mở tài khoản","vui lòng nhập số điện thoại","vui lòng nhập tài khoản ngân hàng",
        "Tên ngân hàng",
      "Vui lòng chọn loại USDT","vui lòng nhập địa chỉ USDT"],
  },
  wallet: {
    default: [
      "Tài sản của tôi",
      "Nạp tiền",
      "Rút tiền",
      "Nhật ký nạp tiền",
      "Nhật ký rút tiền",
      "Số tiền đã nạp",
      "Số tiền đã bán ra",
      "Lợi nhuận đã nhận được",
      "Số dư tài khoản",
      "Số dư",
      "số tiền nạp (USDT)",
      "Số tiền bán ra",
        "lợi nhuận",
        "nâng cấp",
      "nâng cấp thương gia，vui lòng liên hệ chăm sóc khách hàng",
        "xác nhận thương gia gia nhập",
        "xác thực thương gia",
      "bạn cần thanh toán","chứng từ thanh toán","phí bảo đảm của thương gia","xác thực ngay","thông tin","bạn cần thanh toán","số dư không đủ, đã nạp tiền hay chưa"
    ],
    label: [
      "cách thức rút tiền",
      "số tiền rút",
      "mật khẩu quỹ tiền",
      "giao nộp",
      "số điện thoại",
      "email",
      "IFSC",
      "khẳng định",
    ],
    placeholder: [
      "Lựa chọn cách thức rút tiền",
      "Vui lòng nhập số tiền rút",
      "Vui lòng nhập mật khẩu quỹ",
      "Vui lòng lựa chọn cách thức rút tiền",
      "Vui lòng nhập số điện thoại của người nhận tiền",
      "Vui lòng nhập email của người nhận tiền",
      "vui lòng nhập IFSC của người nhận tiền",
    ],
    msg: [
      "Bạn vẫn chưa cài đặt mật khẩu quỹ, vui lòng cài đặt trước",
      "Bạn chưa ràng buộc tài khoản PIX，vui lòng ràng buộc trước",
      "Xác nhận muốn rút tiền?",
    ],
  },
  recharge: {
    default: [
      "Nạp tiền vào ví",
      "Nạp tiền trước vào ví",
      "phương thức nạp tiền",
        "nạp tiền ",
        "xác nhận",
    ],
    label: [
      "thể loại",
      "địa chỉ",
      "số tiền nạp",
      "nhập số tiền",
      "tải lên chứng từ",
        "sao chép thành công",
        "nạp tiền trước đến sàn giao dịch"
    ],
    placeholder: [
      "thể loại",
      "địa chỉ",
    ],
    info: [
      "số tiền nạp",
      "số đơn hàng",
      "ngân hàng nhận tiền",
      "tài khoản nhận tiền",
      "người nhận tiền",
      "sao chép",
    ]
  },
  task: {
    tabs: ["toàn bộ","đang thực hiện", "đã thất bại", "đã hoàn thành","đợi thương gia thanh toán ","đợi xác nhận"],
    default: ["danh sách đơn hàng",],
    msg: ["xác nhận giao nộp", "đã giao nộp，vui lòng đợi xét duyệt", "giao nộp thất bại，vui lòng giao nộp lại"],
  },
  userInfo: {
    default: [
      "Thông tin cá nhân",
      "Ảnh đại diện",
      "Tài khoản",
      "Email",
      "Zhifubao",
      "Thông tin chi tiết",
      "Sửa đổi mật khẩu",
      "Mật khẩu quỹ",
      "Ấn để cài đặt",
      "Sửa đổi ảnh đại diện",
      "Sửa đổi mật khẩu đăng nhập",
      "Sửa đổi mật khẩu quỹ",
      "Giao nộp",
      "xóa bộ nhớ dữ liệu",
      "Kiểm tra thông tin",
      "Nick name",
      "Mã giới thiệu"
    ],
    label: [
      "Mật khẩu đăng nhập cũ",
      "Mật khẩu đăng nhập mới",
      "Xác nhận mật khẩu",
      "Mật khẩu quỹ cũ",
      "Mật khẩu quỹ mới",
      "Xác nhận mật khẩu",
      "Lưu"
    ],
    placeholder: [
      "Vui lòng nhập mật khẩu đăng nhập cũ",
      "Vui lòng nhập mật khẩu đăng nhập mới",
      "Vui lòng xác nhận mật khẩu đăng nhập",
      "Vui lòng nhập mật khẩu quỹ cũ",
      "Vui lòng nhập mật khẩu quỹ mới",
      "Vui lòng xác nhận mật khẩu quỹ",
    ],
  },
  fundRecord: {
    default: ["lịch sử chi ra", "lịch sử nạp tiền", "nhật ký tiền vốn", "nạp", "nhận", "chi"],
    tabs: ["nhận vào", "chi ra", "nạp tiền"],
  },
 dialog: [
    "Nhắc nhở",
    "khẳng định",
    "đang giao nộp...",
    "Sao chép thành công",
    "Phiên bản hệ thống iOS không được hỗ trợ",
    "Đang đăng ký...",
    "số liệu đang tải...",
  ],
  serviceCenter: [
    "trung tâm chăm sóc khách hàng",
    "Xin chào, tôi là chuyên viên chăm sóc khách hàng~",
    "rất vui được phục vụ bạn",
    "tự phục vụ",
    "chăm sóc khách hàng online",
    "chăm sóc khách hàng nạp tiền",
    "Line chăm sóc khách hàng",
  ],
 userTaskRecord: [
    "đơn hàng của tôi",
    "đơn hàng bán ra",
    "đơn hàng mua vào",
    "trạng thái trước đó",
    "hoa hồng nhận được ",
    "đã hoàn thành",
  ],
  withdrawlist: [
    "lịch sử rút tiền",
  ],
  teamReport: {
    default: [
      "danh sách nhóm",
    ],
  },
  common2:['hướng dẫn người mới',"liên hệ chăm sóc","điều khoản dịch vụ，xem chi tiết","chăm sóc khách hàng 1","chăm sóc khách hàng 2"],
  common3:['thành công',"giới thiệu nền tảng"],
  invite:['chia sẻ quảng bá ','mã giới thiệu của tôi',"sao chép mã giới thiệu，giới thiệu càng nhiều bạn bè tham gia","sao chép",'Không thể mời thành viên vào lúc này'],
  common4:['xác thực tên thực',"giao nộp thành công","hạng mục chú ý","nội dung hạng mục","số tiền giao nộp","mặt chính chứng minh thư",
      "chứng minh tài vụ","vui lòng hoàn thành rằng buộc tài khoản trước","vui lòng hoàn thành xác thực tên thật trước"],
  common5:["bán ra","phút","trước","lưu mã qr","mở","đóng băng tài khoản, tạm thời không thể giao dịch","tải APP"],
  common6:["đơn hàng nạp tiền","số đơn hàng","số tiền","thời gian","trạng thái"],
  hall2:["Số tiền bán ra tối thiểu là","","Số lần bán ra vượt quá giới hạn mỗi ngày"],
  register2:["đăng ký bằng email","đăng ký bằng số điện thoại","vui lòng nhập số điện thoại"],
  withdraw2:["rút tiền","tài khoản người bán","số tiền tài khoản","số tiền cần thanh toán","thanh toán ngay","vẫn còn nhiệm vụ chưa hoàn thành, tạm thời không thể rút tiền","Số tiền rút hàng ngày đã vượt quá","chú thích giải thích rõ"],
  mytask2:["tài khoản thương gia","giá tiền","số tiền","số dư tài khoản","số tiền cần thanh toán","thời gian ghép đôi","chi tiết đơn hàng","số lượng không thể là 0","số dư không đủ","giá tiền không thể là 0","phạm vi sai"],
  taskOrder2:["Bạn vẫn có đơn hàng chưa thực hiện, không thể hủy bỏ","số dư không đủ","xác nhận","thất bại","bạn cần phải hoàn thành đơn hàng mới có thể rút tiền:"],
    busAuth2:['thương gia nâng cấp','tôi đồng ý',"xác nhận nâng cấp","thương gia nâng cấp","cần sửa đổi thông tin anh hàng vui lòng liên hệ chăm sóc khách hàng","Tài khoản trước mắt cần được nâng cấp lên tài khoản thương gia"],
    recharge2:["Số tiền không được là 0","phải tải ảnh chụp màn hình lên"]
};