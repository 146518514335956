import Vue from "vue";
import { Locale } from "vant";
import VueI18n from "vue-i18n";

import Chinese from "./cn";
import English from "./en";
import fr from "./fr-fr";
import rus from "./rus";
import deu from "./deu";
import it from "./it";
import nl from "./nl";
import pt from "./pt";

import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "vant/lib/locale/lang/zh-CN";
import tc from "./tc";

import esES from "./es-ES"; //西班牙
import vn from "./vn";
import config from "@/config";

//设置默认语言
var Language = config.Language;

/*初始化*/
const lang = localStorage["Language"] || Language;

let locale = "id-ID";
switch (lang) {
  case "en":
    locale = "en-US";
    Locale.use("en-US", enUS);
    break;
  case "rus":
    locale = "rus";
    Locale.use("rus", rus);
    break;
  case "it":
    locale = "it";
    Locale.use("it", it);
    break;
  case "nl":
    locale = "nl";
    Locale.use("nl", nl);
    break;
  case "pt":
    locale = "pt";
    Locale.use("pt", pt);
    break;
  case "fr":
    locale = "fr";
    Locale.use("fr", fr);
    break;
  case "deu":
    locale = "deu";
    Locale.use("deu", deu);
    break;
  case "tc":
    locale = "zh-HK";
    Locale.use("zh-HK", tc);
    break;
  case "es":
    locale = "es-ES";
    Locale.use("es-ES", esES);
    break;
  case "cn":
    locale = "zh-CN";
    Locale.use("zh-CN", zhCN);
    break;
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: locale,
  messages: {
    "zh-CN": Chinese,
    "en-US": English,
    "es-ES": esES,
    "zh-HK": tc,
    "fr": fr,
    "nl": nl,
    "pt": pt,
    "rus":rus,
    "deu":deu,
    "it":it
  },
});

/*切換*/
export function SetLanguage(lang) {
  i18n.locale = lang;
  switch (lang) {
    case "en-US":
      localStorage["Language"] = "en";
      Locale.use("en-US", enUS);
      break;
    case "rus":
      localStorage["Language"] = "rus";
      Locale.use("rus", rus);
      break;
    case "it":
      localStorage["Language"] = "it";
      Locale.use("it", it);
      break;
    case "nl":
      localStorage["Language"] = "nl";
      Locale.use("nl", nl);
      break;
    case "pt":
      localStorage["Language"] = "pt";
      Locale.use("pt", pt);
      break;
    case "vn":
      localStorage["Language"] = "vn";
      Locale.use("vn", vn);
      break;
    case "fr":
      localStorage["Language"] = "fr";
      Locale.use("fr", fr);
      break;
    case "es-ES":
      localStorage["Language"] = "es";
      Locale.use("es-ES", esES);
      break;
    case "deu":
      localStorage["Language"] = "deu";
      Locale.use("deu", deu);
      break;
    case "zh-HK":
      localStorage["Language"] = "tc";
      Locale.use("zh-HK", tc);
      break;
    case "zh-CN":
      localStorage["Language"] = "cn";
      Locale.use("zh-CN", zhCN);
      break;
  }
}
export default i18n;
