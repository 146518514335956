import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import Vant, { Locale, ImagePreview, Calendar } from "vant";
import { Switch } from 'vant';

import Model from "@/common/Model";
import Dialog from "@/common/Dialog";
import Util from "@/common/Util";
import i18n, { SetLanguage } from "./i18n";
import "vant/lib/index.css";
import "@/assets/css/style.css";
// import '@/assets/css/swiper.css'
import VueResource from "vue-resource";
import Clipboard from "v-clipboard";

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

/*密聊*/
// import MiBScroll from 'better-scroll'
// import MiModel from '@/common/miliao/Model'
// import MiDialog from '@/common/miliao/Dialog'
// import MiUtil from '@/common/miliao/Util'
// import MiInitial from '@/common/miliao/Initial'
// import MiNoContent from '@/components/miliao/NoContent'
// import '@/assets/css/mi-style.css'

// Vue.prototype.$MiBScroll = MiBScroll
// Vue.prototype.$MiModel = MiModel
// Vue.prototype.$MiDialog = MiDialog
// Vue.prototype.$MiUtil = MiUtil
// Vue.prototype.$MiInitial = MiInitial

// Vue.component('MiNoContent', MiNoContent)
import { RadioGroup, Radio } from 'vant';

/*APP*/
Vue.use(Vant);
Vue.use(VueResource);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Clipboard);
Vue.prototype.$SetLanguage = SetLanguage;
Vue.prototype.$ImagePreview = ImagePreview;
Vue.prototype.$Model = Model;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Util = Util;
Vue.prototype.$AppInit = false;
Vue.prototype.$domWidth = document.documentElement.clientWidth;

Vue.config.productionTip = false;

import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import HtmlPanel from "@/components/HtmlPanel";
Vue.component("Footer", Footer);
Vue.component("Loading", Loading);
Vue.component("HtmlPanel", HtmlPanel);
Model.GetBackData((data) => {
  // console.log(data)
});
/*路由登录逻辑*/
router.beforeEach((to, from, next) => {
  if (from.name == "line") {
    Model.GetBackData();
  }
  if (from.name != "login" && from.name != "register") {
    localStorage["FromPage"] = from.fullPath;
  } else {
    localStorage.removeItem("FromPage");
  }
  if (
    to.name != "login" &&
    !localStorage["Token"] &&
    to.matched.some((record) => record.meta.requiresAuth)
  ) {
    next("/login");
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
