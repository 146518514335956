export default {
  mixRecharge: ["Пожалуйста, заполните свой адрес электронной почты"],
  language: "язык",
  common: ["Интернет Сервис", "отменить приостановку","подтверждать","Отмена"],
  upload: ["загрузка...", "неверный формат", "загружено успешно", "Загрузка не удалась"],
  vanPull: ["больше нет данных", "нет данных"],
  login: {
    text: ["язык","Крипто","Авторизоваться"],
    label: ["Вход в систему...", "войти немедленно"],
    placeholder: ["Пожалуйста, введите свой адрес электронной почты или номер мобильного телефона", "Пожалуйста, введите пароль"],
    default: ["Нет аккаунта?", "Зарегистрироваться сейчас","Забыли пароль?","Забыли пароль"],
    codes:["Аккаунт не существует","неверный пароль","Аккаунт заморожен","потерпеть поражение"]
  },
  register: {
    text: [
      "Crypto",
      "Отправка кода подтверждения...",
      "Нажмите, чтобы получить",
      "регистрация...",
      "Зарегистрироваться сейчас",
    ],
    placeholder: [
      "Пожалуйста, введите свой адрес электронной почты",
      "пожалуйста, введите проверочный код",
      "Пожалуйста, введите пароль",
      "Пожалуйста, подтвердите свой пароль",
      "Пожалуйста, введите код приглашения",
      "два разных пароля",
      "пожалуйста, введите проверочный код",
      "неверный адрес электронной почты"
    ],
    label: ["У вас уже есть аккаунт?{a} {line}", "назад войти"],
    codes:["аккаунт уже существует","нет проверочного кода","Неверный код подтверждения","Два пароля не совпадают","Реферер не существует","потерпеть поражение"]
  },
  resetpwd:["Забыли пароль","Пожалуйста, введите свой адрес электронной почты","пожалуйста, введите проверочный код","Нажмите, чтобы получить","пожалуйста введите пароль","сбросить пароль"],
  footer: ["титульная страница", "зал", "Заказ", "моя"],

  home: {
    label:["добро пожаловать"],
    broadcast: "поздравления член{member} стать{grade}",
    menu: ["Журнал средств", "Учебник для начинающих", "Приглашение зарегистрироваться", "Связаться со службой поддержки клиентов"],
    noticeTitle: "Последнее объявление",
    msg: "миссия не открыта",
    video: "Нет видеоурока",
  },
  hall:{
    default:[
      "торговая площадка","остаток средств","я хочу купить","я хочу продать","Подтвердить продажу",
      "Список заказа","заказ на продажу","продать торговцу","Отложенный заказ","купить","продавать"
    ],
    list:[
      'количество','Цена за единицу','Непроданный','Продать USDT','Пожалуйста, введите сумму в долларах USDT','получить сумму',"квота",'Купить USDT','Сумма потрачена'
    ],
    kbip:["отозвать","Избыточное количество","должно быть в пределах суммы"]
  },
  msglist:[
    "список сообщений"
  ],
  sell:{
    placeholder:["Пожалуйста, введите цену продажи","Пожалуйста, введите количество продажи"],
    label:["Пожалуйста, введите количество продаж(USDT)","Имя","банковский счет","Текущая цена(USDT)","продать по лучшей цене","цена продажи","Продать количество USDT","лучшая цена","все","ОК, чтобы продать"]
  },
  buy:{
    placeholder:["Пожалуйста, введите цену покупки","Пожалуйста, введите количество покупки","минимальная сумма","максимальная сумма"],
    label:["остаток средств(USDT)","Имя","банковский счет","Текущая цена(USDT)","купить лучшую цену","цена","Купить количество","лучшая цена","все","Подтвердить покупку","квота"]
  },
  user: {
    default: [
      "персональный центр",
      "войти в аккаунт",
      "Код приглашения",
      "выход",
      "остаток средств",
      "золото",
      "бумажник",
    ],
    menu: [
      "мои активы",
      "список команд",
      "привязка аккаунта",
      "Детали учетной записи",
      "Введение в платформу",
      "выход",
      "процентный кошелек",
    ],
  },
  bindAccount: {
    default: ["привязать учетную запись","представить"],
    fields: ["Страна","SWIFT","КОД СОРТИРОВКИ","Название банка","IBAN","Название счета","Имя","Фамилия","Адрес","Почтовый индекс","Город","Провинция или штат","Мобильный телефон"],
    placeholder: ["Введите страну/регион","Введите SWIFT","Введите КОД СОРТИРОВКИ","Введите название банка","Введите IBAN","Введите номер банковского счета",
       "Пожалуйста,введите свое имя","Пожалуйста,введите свою фамилию","Пожалуйста,введите свой адрес","Пожалуйста,введите свой почтовый индекс","Пожалуйста,введите свой город","Пожалуйста,укажите свою провинцию или штат","Пожалуйста,введите номер вашего мобильного телефона"],
  },
  wallet: {
    default: [
      "Мои активы",
	"Перезарядка",
	"отзывать",
	"Пополнить запись",
	"Регистрация снятий",
	"Сумма пополнения",
	"Проданная сумма",
	"Полученный доход",
	"Остаток на счету",
	"Остаток средств",
	"Сумма пополнения (USDT)",
	"Сумма продажи",
	"Общий доход",
	"Улучшить торговца",
	"Обновите продавца, обратитесь в службу поддержки",
	"Подтвердить зарегистрированного продавца",
	"Бизнес-аутентификация",
	"Вам необходимо заплатить", "Платежный сертификат", "Бизнес-депозит", "Немедленная аутентификация", "Информация", "Вам необходимо заплатить", "Недостаточно остатка или нет"
    ],
    label: [
      "Метод снятия",
       "Сумма вывода",
       "Фондовый пароль",
       "представить",
       "телефонный номер",
       "Почта",
       "ИФСК",
       "Конечно",
    ],
    placeholder: [
      "Выберите способ вывода",
       "Пожалуйста, введите сумму вывода",
       "Пожалуйста, введите пароль фонда",
       "Пожалуйста, выберите способ вывода",
       "Пожалуйста, введите номер мобильного телефона получателя",
       "Пожалуйста, введите адрес электронной почты получателя",
       "Пожалуйста, введите получателя IFSC",
    ],
    msg: [
      "Вы не установили пароль фонда, сначала установите его",
       "Вы не привязали учетную запись PIX, пожалуйста, сначала привяжите",
       "Вы уверены, что хотите снять наличные?",
    ],
  },
  recharge: {
    default: [
      "пополнение кошелька",
      "Пополнение кошелька",
      "Способ пополнения",
      "перезарядка",
      "подтверждать",
    ],
    label: [
      "категория",
      "адрес",
      "Сумма пополнения",
      "Введите сумму",
      "загрузить сертификат",
      "скопировать успешно",
      "Преддепозитный обмен"
    ],
    placeholder: [
      "Виды",
      "адрес",
    ],
    info: [
      "Сумма пополнения",
      "номер заказа",
      "Банк получателя",
      "Коллекторный счет",
      "получатель платежа",
      "копировать",
    ]
  },
  task: {
    tabs: ["Все","Выполняется","Просрочено","Завершено","Ожидание платежа продавца","Ожидание подтверждения"],
    default: ["Список заказа",],
    msg: ["Добавить отзыв", "Отправлено, дождитесь рассмотрения", "Отправить не удалось, пожалуйста, отправьте повторно"],
  },
  userInfo: {
    default: [
      "Персональная информация",
       "аватар",
       "номер счета",
       "Почта",
       "Алипей",
       "Подробности",
       "изменить пароль",
       "Фондовый пароль",
       "Нажмите Настройки",
       "Изменить аватар",
       "Изменить пароль для входа",
       "Изменить пароль фонда",
       "представить",
       "Очистить кэш",
       "Просмотреть информацию",
       "Никнейм",
       "Рекомендуемый код"
    ],
    label: [
     "Исходный пароль для входа",
       "Новый пароль для входа",
       "Подтвердите пароль",
       "Исходный пароль фонда",
       "Новый пароль фонда",
       "Подтвердите пароль",
       "спасти"
    ],
    placeholder: [
      "Пожалуйста, введите исходный пароль для входа",
       "Пожалуйста, введите новый пароль для входа",
       "Пожалуйста, подтвердите пароль для входа",
       "Пожалуйста, введите исходный пароль фонда",
       "Пожалуйста, введите новый пароль фонда",
       "Пожалуйста, подтвердите пароль фонда",
     ],
  },
  fundRecord: {
    default: ["Запись о расходах", "Запись о пополнении", "Журнал активов", "Пополнение", "Поступление", "Обмен"],
    tabs: ["Доход", "Расход", "Пополнение"],
  },
  dialog: [
    "намекать",
     "Конечно",
     "подает...",
     "Копировать успешно",
     "Версия системы IOS низкая и не поддерживает",
     "Регистрация...",
     "загрузка...",
  ],
  serviceCenter: [
    "Обслуживание клиентов",
     "Привет, я эксклюзивный специалист по работе с клиентами~",
     "рад служить вам",
     "самообслуживание",
     "Онлайн-обслуживание клиентов",
     "Подзарядка обслуживания клиентов",
     "Линия обслуживания клиентов",
  ],
  userTaskRecord:[
    "Мой заказ",
     "Заказ на продажу",
     "Купить заказ",
     "Текущий статус",
     "Получить комиссию",
     "завершенный",
  ],
  withdrawlist: [
    "Запись о снятии средств",
  ],
  teamReport: {
    default: [
      "список команд",
    ]
  },
  common2:["Учебник для начинающих", "Связаться со службой поддержки", "Условия обслуживания, посмотреть подробности", "Служба поддержки 1", "Служба поддержки 2"],
  common3:['успех',"Введение в платформу"],
  invite:['поделиться продвижением','мой пригласительный код',"Скопируйте код приглашения, чтобы пригласить больше друзей присоединиться","копировать"],
  common4:['проверено',"Отправлено успешно","Меры предосторожности","Содержание элемента","сумма оплаты","лицевая сторона удостоверения личности",
    "финансовый сертификат","Сначала завершите привязку аккаунта","Пожалуйста, сначала завершите аутентификацию по реальному имени"],
  common5:["Продать", "Минуты", "Ранее", "Сохранить QR-код", "Открыть", "Счет заморожен, временно невозможно торговать", "Скачать приложение"],
  common6:["Пополнить заказ","номер заказа","количество","время","государство"],
  hall2:["Минимальная сумма продажи составляет","","Количество продаж превышает дневной лимит"],
  register2:["Регистрация электронной почты","Зарегистрируйте свой телефон","Пожалуйста, введите номер телефона"],
  withdraw2:["Вывод средств","Аккаунт продавца","Баланс счета","Сумма к оплате","Оплатить сейчас","Есть еще невыполненные задачи, вывод временно отключен","Количество выводов за день превышает", "Примечания"],
  mytask2:["Торговый счет", "цена", "сумма", "баланс счета", "сумма платежа", "время сопоставления", "детали заказа", "количество не может быть 0", "недостаточный баланс", "цена не может быть 0 ","Неправильный диапазон"],
  taskOrder2:["У вас остались невыполненные заказы, которые нельзя отменить", "Недостаточно баланса", "Подтверждено", "Не удалось", "Вам еще нужно выполнить заказ на вывод наличных:"],
  busAuth2:["Бизнес-обновление","Я согласен","Подтвердить обновление","Обновить мерчант","Пожалуйста, свяжитесь со службой поддержки, если вам нужно изменить данные банковской карты","Текущий счет необходимо обновить до торгового счета"],
  recharge2:["Сумма не может быть равна 0", "Скриншот должен быть загружен"],
  buy3:["Обратный отсчет","час","тип","социальный заказ","совместный заказ на продажу","ограниченное количество людей","не вводить неограниченное количество людей","указанный идентификатор пользователя","пожалуйста, введите указанный идентификатор"],
  hall3:["Дни","Часы","Минуты","Секунды","Порядок комбинирования","Счет","Срок действия"],
  sell3:['Цена продажи не может быть выше лучшей цены'],
  busAuth3:["член","торговец","Коронный торговец"],
  recharge3:["рассчитать"],
  withdraw3:{
    fields:["Имя учетной записи", "телефонный номер", "IBAN","название банка","Код банка"],
    placeholder:["Пожалуйста, введите имя учетной записи","Пожалуйста, введите номер телефона","Пожалуйста, введите IBAN","Пожалуйста, введите название банка","Пожалуйста, выберите код банка"],
  },
  my2:["день", "процентная ставка", "процентный кошелек", "детали"],
  fin:["Сумма", "Срок", "Процентная ставка", "Срок действия"]
};
